const state = {
  globalData: {
    comment: 'BAILUTECH1',
    theme: {
      color: '#00DE93',
      color1: '#ff993e',
      color2: '#00cca2'
    },
    brand: {
      tabbar:{}
    },
    desc: '微点云助教学员端'
  },
  defaultAddress: {},
  orderSubmitPage: {},
  catch_components:[],
  qywx_profile:0,
  tabbars:{
    home: false,
    mall: false,
    profile: false,
    schedule: false,
  }
}

const mutations = {
  updateBrandInfo(state, data) {
    state.globalData.brand = data
  },
  updateCatchComponents(state, data){
    state.catch_components = data
  },
  ADD_QYWX_PROFILE(state, data){
    state.qywx_profile = state.qywx_profile + 1
  },
  ADD_TABBAR(state,data){
    state.tabbars = data
  }
}

const actions = {
    set_qywx_profile({ commit }){
      commit('ADD_QYWX_PROFILE', 1)
    },
    set_tabbar({ commit }, query){
      // console.log(query)
      commit('ADD_TABBAR', query)
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
