const getters = {
  // user
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  loginStatus: state => state.user.loginStatus,
  // app
  globalData: state => state.app.globalData,
  catch_components: state => state.app.catch_components,
  qywx_profile: state => state.app.qywx_profile,
  tabbars: state => state.app.tabbars,
}
export default getters
