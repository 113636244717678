import { isEmpty } from '@/common/validate'

/**
 * 空数据占位
 * @param {any} value
 * @param {String} def 自定义占位
 */
export function dataPlaceholder(value, def) {
  if (isEmpty(value)) {
    return def || '--'
  } else {
    return value
  }
}

/**
 * 转为数字
 * @param {any} value
 * @param {String} def 自定义占位
 */
export function formatNumber(value, def) {
  return Number(value)
}
