import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vant/lib/index.less'
import './styles/iconfont.css'
import api from './api'
import util from './common/util'
import './common/flexible'
import './permission' // 鉴权
import AudioVisual from 'vue-audio-visual'
import VueSignaturePad from 'vue-signature-pad'
import VueAMap from 'vue-amap'
import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'
import VueHtml2Canvas from 'vue-html2canvas'
import vueHashCalendar from 'vue-hash-calendar'
import 'vue-hash-calendar/lib/vue-hash-calendar.css'
import "vue-easytable/libs/theme-default/index.css"
import VueEasytable from "vue-easytable"
import VConsole from 'vconsole'
import Fragment from 'vue-fragment'
// Vue.prototype.$vConsole= new VConsole()

if(localStorage.appid == 'RGid7'){
  // Vue.prototype.$vConsole= new VConsole()
}


Vue.use(Fragment.Plugin)
Vue.use(VueEasytable)
Vue.use(vueHashCalendar)
Vue.use(VueClipboard)
Vue.use(VueHtml2Canvas)
Vue.use(VueWechatTitle)
Vue.use(require('vue-cookies'))
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '898f4d2e266c3b8611e7fc3db009b284',
  plugin: ['AMap.Autocomplete', 'AMap.Geocoder','AMap.Geolocation'],
  v: '1.4.6'
})

Vue.use(VueSignaturePad)
Vue.use(AudioVisual)
// 引入 Vant 基础组件及弹窗
import { Button, Image, List, Cell, CellGroup, Icon, Row, Col, Popup, Toast, Dialog, Loading, Lazyload } from 'vant'

Vue.use(Button).use(Image).use(List).use(Cell).use(CellGroup).use(Icon).use(Row).use(Col).use(Popup).use(Loading).use(Lazyload)

// 使用Image  会保install 错误,可能是测试版本的原因
// 在 Vue 的 prototype 上挂载 $toast 方法，便于在组件内调用(自动挂载,真机出现问题)

Vue.config.productionTip = false
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$api = api
Vue.prototype.$util = util

Vue.config.ignoredElements = [ // 忽略自定义元素标签抛出的报错
  'wx-open-launch-app', 
];

import mock from '../mock'
Vue.prototype.$mock = mock
// 过滤器
import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

if (process.env.NODE_ENV === 'development') {
  const getBrowser = function getBrowser() {
    var ua = navigator.userAgent.toLowerCase()
    var inWechat = /micromessenger/.test(ua)
    return inWechat
  }
  const isMobile = function() {
    return !!/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }
  console.log(getBrowser() ? '在微信浏览器中' : '不在微信浏览器中')

  if (getBrowser() && isMobile) {
    var vConsole = require('./utils/vconsole')
    console.log('vConsole 面板初始化', vConsole.version)
  }
  // 本地调试
  // window.localStorage.setItem('Access-Token', '963bd304-a13a-451a-a2f8-1d073df6f3e6')
  // document.cookie = 'Login-Status=2'


}
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')