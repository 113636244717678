import Mock from 'mockjs'

const indexList = Mock.mock({
  'code': 200,
  'data|10': [
    {
      'id': '@id',
      'name': `@string(6)校区`,
      'image': Mock.Random.image()
    }
  ],
  'success': true,
  'msg': '成功'

})
const commentList = Mock.mock({
  'code': 200,
  'data|7': [
    {
      'id': '@id',
      'name': '@string(6)',
      'date': '@date',
      'rate': 8,
      'desc': '111111111',
      'images': ['http://i01.wedots.cn/10002/2017/09/27/e97f2ee03a8d32ea33401cb16adb5040.jpg?imageMogr2/thumbnail/160x160!/quality/75/format/png', 'http://i01.wedots.cn/10002/2017/09/27/e97f2ee03a8d32ea33401cb16adb5040.jpg?imageMogr2/thumbnail/160x160!/quality/75/format/png'],
      'avatar': Mock.Random.image()

    }],
  'success': true,
  'msg': '成功'
})

export default {
  indexList,
  commentList
}
