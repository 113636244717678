
import api from '@/api'
import store from '@/store/index'

/**
 * sign 微信签名
 */
export const wechatConfig = function() {
  console.log('触发了微信config')
  return new Promise(function(resolve, reject) {
    api.jssdk_index({path:window.location.href})
      .then(res => {
        const data = res.data
        console.log('--微信sdk配置注入--')
        wx.config({
          debug: process.env.NODE_ENV === 'development', // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: [
            'updateAppMessageShareData', // 新接口，自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
            'updateTimelineShareData', // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
            'onMenuShareAppMessage', // 老接口，分享朋友
            'onMenuShareTimeline', // 老接口，分享到朋友圈
            'onMenuShareQQ',
            'onMenuShareQZone',
            'chooseWXPay',
            'translateVoice',
            'startRecord',
            'stopRecord',
            'onVoiceRecordEnd',
            'playVoice',
            'onVoicePlayEnd',
            'pauseVoice',
            'stopVoice',
            'uploadVoice',
            'downloadVoice',
            'getLocation',
            'openLocation',
            'scanQRCode'
          ], // 必填，需要使用的JS接口列表
          openTagList:['wx-open-launch-weapp'],
          success: function(succRes) {
            console.log('验证成功返回的信息:', succRes)
          },

        })
        wx.ready(function() {
          // wx.onMenuShareAppMessage({
          //   title: '来自简单的分享', // 分享标题
          //   desc: '测试分享功能', // 分享描述
          //   link: `${location.href}&_source=133`, // 分享链接
          //   imgUrl: '', // 分享图标
          //   type: 'link', // 分享类型,music、video或link，不填默认为link
          //   success: function () {

          //   },
          //   cancel: function (err) {
          //     console.log('分享失败',err)
          //   }
          // })
          // 页面加载就需要调用的sdk接口，可放在resolve这里调用
          resolve()
        })
        wx.error(function(errRes) {
          console.log('验证失败返回的信息:', errRes)
          // this.$router.push('/404')
          // store.dispatch('')
          reject(errRes)
        })
        if(data.appStatus==0){
          if(window.location.pathname!="/expire"){
            window.location.replace('expire')
          }
          //store.dispatch('/404')

        // console.log(store);
        // console.log(store.$router);

          // this.$router.push('/404')
          // this.$store.commit('app/expire')
        }
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })


  })
}

/**
 * sign 企业微信签名
 */
export const weWorkchatConfig = function() {
  console.log('触发了微信config')
  return new Promise(function(resolve, reject) {
    api.qywx_jssdk_index({path:window.location.href})
      .then(res => {
        const data = res.data
        console.log('--微信sdk配置注入--')
        wx.config({
          beta: true,
          debug: process.env.NODE_ENV === 'development', // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.jsapi.appId, // 必填，公众号的唯一标识
          timestamp: data.jsapi.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.jsapi.nonceStr, // 必填，生成签名的随机串
          signature: data.jsapi.signature, // 必填，签名
          jsApiList: ['scanQRCode'], //必填
        })
        wx.ready(function() {
          // 页面加载就需要调用的sdk接口，可放在resolve这里调用
          wx.agentConfig({
            corpid: data.agentConfig.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: data.agentConfig.agentid, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: data.agentConfig.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.agentConfig.nonceStr, // 必填，生成签名的随机串
            signature: data.agentConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: [
              'openEnterpriseChat',
              'selectExternalContact',
              'sendChatMessage',
              'getCurExternalChat',
              'getCurExternalContact',
              'openUserProfile',
              'getClipboardData',
              'openLocation'
            ], //必填
            success: function(res) {
                console.log(res,'我过了吧')
                store.dispatch('app/set_qywx_profile')
                resolve(res)
            },
            fail: function(res) {
                if(res.errMsg.indexOf('function not exist') > -1){
                    alert('版本过低请升级')
                }
                console.log('验证失败返回的信息:', res)
                reject(res)
            }
          })
        })
        wx.error(function(errRes) {
          console.log('验证失败返回的信息:', errRes)
          reject(errRes)
        })
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}
/**
 * 微信支付
 * @param {*} timeStamp
 * @param {*} nonceStr
 * @param {*} packageStr
 * @param {*} signType
 * @param {*} paySign
 */
export const wxPay = function(appId,timeStamp, nonceStr, packageStr, signType = 'MD5', paySign) {
  return new Promise(function(resolve, reject) {
    // wx.chooseWXPay({
    //   timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
    //   nonceStr, // 支付签名随机串，不长于 32 位
    //   'package': packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    //   signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
    //   paySign, // 支付签名
    //   success: res => {
    //     // 支付成功后的回调函数
    //     resolve(res)
    //   },
    //   fail: err => {
    //     console.error(err)
    //     reject(err)
    //   }
    // })

    wx.invoke(
      'getBrandWCPayRequest', {
        appId,
        timeStamp,
        nonceStr, // 支付签名随机串，不长于 32 位
        'package': packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign, // 支付签名
      },
      function(res){
      if(res.err_msg == "get_brand_wcpay_request:ok" ){
      // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            resolve(res)
      }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
        reject(9)
      }else if(res.err_msg == "get_brand_wcpay_request:fail"){
        reject(res)
      }else{
        resolve(res)
      }
    }); 



  })
}

/**
 * 获取地理位置接口
 * @param {*} type
 */
export const getLocation = function(type = 'wgs84') {
  return new Promise(function(resolve, reject) {
    wx.getLocation({
      type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: res => {
        resolve(res)
      },
      fail: err => {
        console.error(err)
        reject(err)
      }
    })
  })
}

/**
 * 使用微信内置地图查看位置接口
 * @param {*} type
 */
export const openLocation = function({ latitude, longitude, name, address, scale = 10, infoUrl = '' }) {
  return new Promise(function(resolve, reject) {
    wx.openLocation({
      latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude, // 经度，浮点数，范围为180 ~ -180。
      name, // 位置名
      address, // 地址详情说明
      scale, // 地图缩放级别,整形值,范围从1~28。默认为最大
      infoUrl,// 在查看位置界面底部显示的超链接,可点击跳转
      success:function(res){
        console.log(res)
      },
      fail:function(err){
        console.log(err)
      },
    })
  })
}

/**
 * 微信扫一扫
 * @param {*} needResult
 * @param {*} scanType
 */
export const scanQRCode = function(needResult = 0, scanType = ['qrCode', 'barCode']) {
  return new Promise(function(resolve, reject) {
    wx.scanQRCode({
      needResult, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType, // 可以指定扫二维码还是一维码，默认二者都有
      success: res => {
        resolve(res)
      },
      fail: err => {
        console.error(err)
        reject(err)
      }
    })
  })
}

/**
 * 微信分享给朋友
 * @param {*} needResult
 * @param {*} scanType
 */
 export const updateAppMessageShareData = function(title,desc,link,imgUrl) {
  return new Promise(function(resolve, reject) {
    wx.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl, // 分享图标
      success: res => {
        resolve(res)
      },
      fail: err => {
        console.error(err)
        reject(err)
      }
    })
  })
}

/**
 * 企业微信-发送消息
 * @param {*} timeStamp
 * @param {*} nonceStr
 * @param {*} packageStr
 * @param {*} signType
 * @param {*} paySign
 */
export const wxWorkSend = function(content) {
  return new Promise(function(resolve, reject) {
    wx.invoke('sendChatMessage', content, function(res){
      console.log(res)
      if (res.err_msg == 'sendChatMessage:ok') {
          //发送成功
          resolve(res)
      }else{
          reject(res)
      }
    })

  })
}

/**
 * 企业微信-打开个人信息页
 * @param {*} timeStamp
 * @param {*} nonceStr
 * @param {*} packageStr
 * @param {*} signType
 * @param {*} paySign
 */
export const wxWorkOpenUserProfile = function(obj) {
  return new Promise(function(resolve, reject) {
    wx.invoke('openUserProfile', {
      "type": obj.type, //1表示该userid是企业成员，2表示该userid是外部联系人
      "userid": obj.userid//可以是企业成员，也可以是外部联系人
      }, function(res){
      if(res.err_msg != "openUserProfile:ok"){
        reject(res)
      //错误处理
      }
    })
  })
}


