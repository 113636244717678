import api from '@/api'
import {
  saveToken,
  saveLoginStatus,
  saveUserInfo,
  removeToken,
  removeUserInfo,
  removeLoginStatus,
  loadLoginStatus,
  loadToken,
  loadUserInfo
} from '@/utils/cache'
const state = {
  loginStatus: loadLoginStatus(), // 登录状态
  token: loadToken(), // token
  userInfo: loadUserInfo() // 用户登录信息
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  // 登录相关，通过code获取token和用户信息，用户根据自己的需求对接后台
  loginWechatAuth({ commit }, code) {
    const data = {
      code: code
    }
    return new Promise((resolve, reject) => {
      api.auth_wechat(data)
        .then(res => {
          // userInfo
          if (res.data && res.data.wx_info && res.data.wx_info.nickname) {
            const userInfo = {
              city: res.data.wx_info.city,
              country: res.data.wx_info.country,
              province: res.data.wx_info.province,
              privilege: res.data.wx_info.privilege,
              language: res.data.wx_info.language,
              nickName: res.data.wx_info.nickname,
              avatarUrl: res.data.wx_info.headimgurl,
              sex: String(res.data.wx_info.sex)
            }
            commit('SET_USERINFO', saveUserInfo(userInfo))
          }
          // cellphone
          if (res.data && res.data.cellphone) {
            console.log('已有手机号')
          } else {
            console.log('没有手机号')
          }
          let openid_name = `${localStorage.getItem('appid')}-openid`
          localStorage.setItem(openid_name,res.data.openid)
          // token
          if (res.data && res.data.access_token) {
            let name = `${localStorage.getItem('appid')}-Access-Token`
            const token = res.data.access_token
            commit('SET_TOKEN', saveToken(token,name))
            api.daily_active({ access_type : 'h5' })
          } else {
            console.error('没有access_token')
          }
          resolve(res)
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  },
  loginQYWechatAuth({ commit }, val) {
    const data = {
      code: val.code,
      corpid: val.corpid,
      testcode: "testcode",
    }
    return new Promise((resolve, reject) => {
      api.qywx_auth_wechat(data)
        .then(res => {
          // cellphone
          if (res.data && res.data.cellphone) {
            console.log('已有手机号')
          } else {
            console.log('没有手机号')
          }
          // token
          if (res.data && res.data.access_token) {
            let name = `${localStorage.getItem('appid')}-Access-Token`
            const token = res.data.access_token
            commit('SET_TOKEN', saveToken(token,name))
            api.daily_active({ access_type : 'h5-qywx-employee' })
          } else {
            console.error('没有access_token')
          }
          resolve(res)
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  },
  // 设置状态
  setLoginStatus({ commit }, query) {
    if (query === 0 || query === 1) {
      removeToken()
      removeUserInfo()
    }
    // 设置不同的登录状态
    commit('SET_LOGIN_STATUS', saveLoginStatus(query))
  },
  // 登出
  fedLogOut() {
    // 删除token，用户信息，登陆状态
    removeToken()
    removeUserInfo()
    removeLoginStatus()
  },
  // 单独设置token
  setTokenAlone({ commit }, val){
    commit('SET_TOKEN', saveToken(val.token,val.name))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
