import axios from 'axios'
import { Toast } from 'vant'
// import Qs from 'qs'
import router from '@/router'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// 请求(http request)拦截器 处理
service.interceptors.request.use(
  config => {
    if(router.currentRoute.query._source){
      config.headers['Source'] = router.currentRoute.query._source
    }
    let openid_name = `${localStorage.getItem('appid')}-openid`
    if(localStorage.getItem(openid_name)){
      config.headers['Openid'] = localStorage.getItem(openid_name)
    }
    config.headers['Appid'] = localStorage.getItem('appid')
    config.headers['Corpid'] = localStorage.getItem('corpid')
    if (store.getters.token) {
        config.headers['Access-Token'] = store.getters.token
      }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

// 响应(http response)拦截器 处理
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      Toast({
        message: `${res.msg || 'Error'} ${response['headers']['x-debug-tag']?response['headers']['x-debug-tag']:''}`,
        duration: 2 * 1000,
        forbidClick: true
      })
      if(res.code === 10002){
        return false
      }
      // return Promise.reject(new Error(res.msg || 'Error'))
      return Promise.reject(`${res.msg || 'Error'} ${response['headers']['x-debug-tag']?response['headers']['x-debug-tag']:''}`)
    } else {
      return res
    }
  },
  error => {
    const { response } = error
    console.log('请求响应失败', error, response)
    if (response) {
      // 请求已发出，但服务器响应的状态码不在 2xx 范围内
      errorTips(response)
      return Promise.reject(response)
    } else {
      // 网络超时,断网,请求不存在等
      // alert('哎呦，网络开小差了≧﹏≦!')
      // Toast({
      //   message: '哎呦，网络开小差了≧﹏≦!',
      //   duration: 5 * 1000,
      //   forbidClick: true
      // })
      return Promise.reject(error)
    }
  }
)

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
// const toLogin = () => {
  // setTimeout(() => {
  //   router.replace({
  //     path: '/login',
  //     query: { redirect: router.currentRoute.fullPath }
  //   })
  // }, 1500)
  // store.dispatch('user/fedLogOut').then(() => {
  //   location.reload()
  // })
// }
/**
 * 请求响应异常处理
 */
const errorTips = (response) => {
  switch (response.status) {
    // 401: 未登录
    // 未登录则跳转登录页面，并携带当前页面的路径
    // 在登录成功后返回当前页面，这一步需要在登录页操作。
    // token失效，登陆超时
    case 401:
      store.dispatch('user/fedLogOut').then(() => {
        location.reload()
      })
      break
      // 403 该用户没有权限
    case 403:
      Toast({
        message: `${response.status}:该用户没有权限`,
        duration: 1500,
        forbidClick: true
      })
      router.push({
          path: '/user',
      })
      break
      // 404请求不存在
    case 404:
      Toast({
        message: `${response.status}:网络请求不存在`,
        duration: 1500,
        forbidClick: true
      })
      break
      // 500服务器出错
    case 500:
      Toast({
        message: `${response.status}:服务器出错`,
        duration: 1500,
        forbidClick: true
      })
      break
      // 其他错误，直接抛出错误提示
    default:
      Toast({
        message: `${response.status}：${response.data}`,
        duration: 1500,
        forbidClick: true
      })
  }
}

export default service
