import request from '@/common/request'
// import Qs from 'qs'

// --学员中心
/**
 * 个人中心
 * @param {*} data
 */
export const profile_index = function(data) {

  return request.post(`/profile/index`, data)
}

/**
 * 学员信息编辑
 * @param {*} data
 */
export const profile_update = function(data) {
  return request.post(`/profile/update`, data)
}

/**
 * 学员卡片
 * @param {*} data
 */
 export const profile_namecard = function(data) {
  return request.post(`/profile/namecard`, data)
}

/**
 * 学员积分获取记录
 * @param {*} data
 */
export const profile_credits = function(data) {
  return request.post(`/profile/credits`, data)
}

/**
 * 学员切换
 * @param {*} data
 */
export const profile_switch = function(data) {
  return request.post(`/profile/switch`, data)
}
/**
 * 权限判断
 * @param {*} data
 */
export const profile_campus = function(data) {
  return request.post(`/profile/campus`, data)
}

/**
 * 关联家属
 * @param {*} data
 */
export const profile_contact = function(data) {
  return request.post(`/profile/contact`, data)
}

/**
 * 我的余额
 * @param {*} data
 */
 export const profile_wallet = function(data) {
  return request.post(`/profile/wallet`, data)
}

/**
 * 我的班级
 * @param {*} data
 */
export const profile_classes = function(data) {
  return request.post(`/profile/classes`, data)
}

/**
 * 我的班级
 * @param {*} data
 */
 export const profile_order_list = function(data) {
  return request.post(`/profile/order-list`, data)
}

/**
 * 关联家属
 * @param {*} data
 */
export const profile_add_contact = function(data) {
  return request.post(`/profile/add-contact`, data)
}

/**
 * 关联学员
 * @param {*} data
 */
 export const profile_add_kids = function(data) {
  return request.post(`/profile/add-kids`, data)
}

/**
 * 关联家属
 * @param {*} data
 */
export const profile_contact_detail = function(data) {
  return request.post(`/profile/contact-detail`, data)
}


/**
 * 关联家属
 * @param {*} data
 */
export const profile_update_contact = function(data) {
  return request.post(`/profile/update-contact`, data)
}

/**
 * 关联家属
 * @param {*} data
 */
 export const profile_del_contact = function(data) {
  return request.post(`/profile/del-contact`, data)
}

/**
 * 退出登录
 * @param {*} data
 */
export const auth_logout = function(data) {
  return request.post(`/auth/logout`, data)
}

// --通用筛选

/**
 * 校区选择
 * @param {*} data
 */
export const filter_studios = function(data) {
  return request.post(`/filter/studios`, data)
}

/**
 * 校区区域选择
 * @param {*} data
 */
export const filter_district = function(data) {
  return request.post(`/filter/district`, data)
}

/**
 * 试听校区区域选择
 * @param {*} data
 */
export const filter_districtstudio = function(data) {
  return request.post(`/filter/district-studio`, data)
}

/**
 * 校区教师
 * @param {*} data
 */
export const filter_courseTeacher = function(data) {
  return request.get(`/filter/course-teacher`, {params:data})
}

/**
 * 校区和科目（无需token）
 * @param {*} data
 */
export const recruit_filter = function(data) {
  return request.post(`/recruit/filter`, {params:data})
}



// -- 焦点图

/**
 * 焦点图
 * @param {*} data
 */
export const banner_index = function(data) {
  return request.post(`/banner/index`, data)
}

// --系统信息

/**
 * 系统信息
 * @param {*} data
 */
export const brand_index = function(data) {
  return request.post(`/brand/index`, data)
}

// --老师信息

/**
 * 老师信息详情
 * @param {*} data
 */
export const teacher_detail = function(data) {
  return request.post(`/teacher/detail`, data)
}

/**
 * 老师授课课表
 * @param {*} data
 */
export const teacher_schedule = function(data) {
  return request.post(`/teacher/schedule`, data)
}

/**
 * 老师点评信息
 * @param {*} data
 */
export const teacher_comment = function(data) {
  return request.post(`/teacher/comment`, data)
}

// --upload
/**
 * upload -- 获取七牛云token
 * @param {*} data
 */
export const upload_image = function(data) {
  return request.post(`/upload/image-token`, data)
}
// --JSSDK
/**
 * JSSDK
 * @param {*} data
 */
export const jssdk_index = function(data) {
  return request.post(`/jssdk/index`, data)
}

// --企业微信JSSDK
/**
 * 企业微信JSSDK
 * @param {*} data
 */
export const qywx_jssdk_index = function(data) {
  return request.post(`/qywx/jssdk/index`, data)
}

/**
 *  media_id
 * @param {*} data
 */
export const qywx_jssdk_upload_media = function(data) {
  return request.post(`/qywx/jssdk/upload-media`, data)
}

// --校区列表

/**
 * 校区列表
 * @param {*} data
 */
export const campus_index = function(data) {
  return request.post(`/campus/index`, data)
}

/**
 * 校区详情
 * @param {*} data
 */
export const campus_detail = function(data) {
  return request.post(`/campus/detail`, data)
}

/**
 * 校区课表
 * @param {*} data
 */
export const campus_schedule = function(data) {
  return request.post(`/campus/schedule`, data)
}

/**
 * 校区课表
 * @param {*} data
 */
 export const campus_monitor = function(data) {
  return request.post(`/employee/monitor/index`, data)
}

/**
 * tabbar红点
 * @param {*} data
 */
export const campus_tabbar = function(data) {
  return request.post(`/campus/tabbar`, data)
}

/**
 * 2899弄Cai
 * @param {*} data
 */
 export const cai_cat = function(data) {
  return request.post(`/cai/cat`, data)
}

/**
 * 2899弄Cai
 * @param {*} data
 */
export const tax_index = function(data) {
  return request.post(`/tax/index`, data)
}

export const cai_order = function(data) {
  return request.post(`/cai/order`, data)
}

export const cai_finish = function(data) {
  return request.post(`/cai/finish`, data)
}

export const cai_list = function(data) {
  return request.post(`/cai/list`, data)
}

export const cai_receive = function(data) {
  return request.post(`/cai/receive`, data)
}

export const cai_cart = function(data) {
  return request.post(`/cai/cart`, data)
}

export const cai_del_cart = function(data) {
  return request.post(`/cai/del-cart`, data)
}

export const cai_add_cart = function(data) {
  return request.post(`/cai/add-cart`, data)
}
// --在线商城

/**
 * 在线商城
 * @param {*} data
 */
export const store_cat = function(data) {
  return request.post(`/store/cat`, data)
}

/**
 * 在线商城
 * @param {*} data
 */
export const store_index = function(data) {
  return request.post(`/store/index`, data)
}

/**
 * 商品详情
 * @param {*} data
 */
export const store_product = function(data) {
  return request.post(`/store/product`, data)
}

/**
 * 商品订单点评
 * @param {*} data
 */
export const store_productcomment = function(data) {
  return request.post(`/store/product-comment`, data)
}

// --我的优惠券

/**
 * 优惠券
 * @param {*} data
 */
export const coupon_index = function(data) {
  return request.post(`/coupon/index`, data)
}

/**
 * 有效优惠券
 * @param {*} data
 */
export const coupon_active = function(data) {
  return request.post(`/coupon/active`, data)
}

/**
 * 兑换优惠券
 * @param {*} data
 */
export const coupon_exchange = function(data) {
  return request.post(`/coupon/exchange`, data)
}

// --商城订单

/**
 * 查询商城订单
 * @param {*} data
 */
export const order_index = function(data) {
  return request.post(`/order/index`, data)
}

/**
 * 创建商城订单
 * @param {*} data
 */
export const order_createorder = function(data) {
  return request.post(`/order/create-order`, data)
}

/**
 * 商城订单详情
 * @param {*} data
 */
export const order_detail = function(data) {
  return request.post(`/order/detail`, data)
}

/**
 * 取消商城订单
 * @param {*} data
 */
export const order_cancelorder = function(data) {
  return request.post(`/order/cancel-order`, data)
}

/**
 * 商城订单配送
 * @param {*} data
 */
export const order_express = function(data) {
  return request.post(`/order/express`, data)
}

/**
 * 订单可退款金额
 * @param {*} data
 */
export const order_totalcanrefund = function(data) {
  return request.post(`/order/total-can-refund`, data)
}

/**
 * 订单退款申请
 * @param {*} data
 */
export const order_refund = function(data) {
  return request.post(`/order/refund`, data)
}

/**
 * 订单评价信息
 * @param {*} data
 */
export const order_comment = function(data) {
  return request.post(`/order/comment`, data)
}

/**
 * 订单评价
 * @param {*} data
 */
export const order_addcomment = function(data) {
  return request.post(`/order/add-comment`, data)
}

// --购物车

/**
 * 购物车列表
 * @param {*} data
 */
export const cart_index = function(data) {
  return request.post(`/cart/index`, data)
}

/**
 * 获取购物车
 * @param {*} data
 */
export const cart_get = function(data) {
  return request.post(`/cart/get`, data)
}

/**
 * 加入购物车
 * @param {*} data
 */
export const cart_set = function(data) {
  return request.post(`/cart/set`, data)
}

/**
 * 购物车商品删除
 * @param {*} data
 */
export const cart_del = function(data) {
  return request.post(`/cart/del`, data)
}

/**
 * 购物车商品删除
 * @param {*} data
 */
export const cart_reduce = function(data) {
  return request.post(`/cart/reduce`, data)
}

/**
 * 购物车统计
 * @param {*} data
 */
export const cart_count = function(data) {
  return request.post(`/cart/count`, data)
}

/**
 * 清空购物车
 * @param {*} data
 */
export const cart_clear = function(data) {
  return request.post(`/cart/clear`, data)
}

// --收货地址

/**
 * 收货地址
 * @param {*} data
 */
export const address_index = function(data) {
  return request.post(`/address/index`, data)
}

/**
 * 添加收货地址
 * @param {*} data
 */
export const address_add = function(data) {
  return request.post(`/address/add`, data)
}

/**
 * 删除收货地址
 * @param {*} data
 */
export const address_delete = function(data) {
  return request.post(`/address/delete`, data)
}

/**
 * 默认地址信息
 * @param {*} data
 */
export const address_default = function(data) {
  return request.post(`/address/default`, data)
}

/**
 * 收货地址详情
 * @param {*} data
 */
export const address_detail = function(data) {
  return request.post(`/address/detail`, data)
}

/**
 * 收货地址编辑
 * @param {*} data
 */
export const address_update = function(data) {
  return request.post(`/address/update`, data)
}

// --学员课表

/**
 * 学员课表
 * @param {*} data
 */
export const schedule_index = function(data) {
  return request.post(`/schedule/index`, data)
}

/**
 * 课程详情
 * @param {*} data
 */
export const schedule_detail = function(data) {
  return request.post(`/schedule/detail`, data)
}

/**
 * 等位列表
 * @param {*} data
 */
 export const schedule_waitlist = function(data) {
  return request.post(`/schedule/waitlist`, data)
}

/**
 * 学员列表
 * @param {*} data
 */
 export const schedule_booklist = function(data) {
  return request.post(`/schedule/booklist`, data)
}

/**
 * 课程详情
 * @param {*} data
 */
 export const schedule_cancel_wait = function(data) {
  return request.post(`/schedule/cancel-wait`, data)
}
/**
 * 课程详情
 * @param {*} data
 */
 export const schedule_live = function(data) {
  return request.post(`/schedule/live`, data)
}

/**
 * 课程预约
 * @param {*} data
 */
export const schedule_booking = function(data) {
  return request.post(`/schedule/booking`, data)
}

/**
 * 课后反馈
 * @param {*} data
 */
export const schedule_feedback = function(data) {
  return request.post(`/schedule/feedback`, data)
}

/**
 * 评价老师
 * @param {*} data
 */
export const schedule_waitforcomment = function(data) {
  return request.post(`/schedule/wait-for-comment`, data)
}

/**
 * 课程点评
 * @param {*} data
 */
 export const schedule_comment= function(data) {
  return request.post(`/schedule/comment`, data)
}

/**
 * 课程评论点赞
 * @param {*} data
 */
 export const schedule_like_or_cancel = function(data) {
  return request.post(`/schedule/like-or-cancel`, data)
}

/**
 * 课程点评
 * @param {*} data
 */
export const schedule_addcomment = function(data) {
  return request.post(`/schedule/add-comment`, data)
}

/**
 * 课程备课
 * @param {*} data
 */
 export const schedule_summary = function(data) {
  return request.post(`/schedule/summary`, data)
}

/**
 * 学员请假
 * @param {*} data
 */
export const schedule_checkout = function(data) {
  return request.post(`/schedule/check-out`, data)
}

// 助学工具

/**
 * 课程专栏
 * @param {*} data
 */
export const content_index = function(data) {
  return request.post(`/content/index`, data)
}

/**
 * 课程专栏详情介绍
 * @param {*} data
 */
export const content_packagedetail = function(data) {
  return request.post(`/content/package-detail`, data)
}

/**
 * 课程专栏的视频列表
 * @param {*} data
 */
export const content_packagecontent = function(data) {
  return request.post(`/content/package-content`, data)
}

/**
 * 视频内容详情
 * @param {*} data
 */
export const content_detail = function(data) {
  return request.post(`/content/detail`, data)
}

/**
 * 获取视频播放地址
 * @param {*} data
 */
export const content_play = function(data) {
  return request.post(`/content/play`, data)
}

/**
 * 获取视频ppt地址
 * @param {*} data
 */
export const content_ppt = function(data) {
  return request.post(`/content/ppt`, data)
}

/**
 * 课程评论信息
 * @param {*} data
 */
export const content_comment = function(data) {
  return request.post(`/content/comment`, data)
}

/**
 * 添加课程的评论
 * @param {*} data
 */
export const content_addcomment = function(data) {
  return request.post(`/content/add-comment`, data)
}

/**
 * 活动中心
 * @param {*} data
 */
export const events_index = function(data) {
  return request.post(`/events/index`, data)
}

/**
 * 活动详情【未完成】
 * @param {*} data
 */
export const events_detail = function(data) {
  return request.post(`/events/detail `, data)
}

/**
 * 活动详情【未完成】
 * @param {*} data
 */
export const events_book = function(data) {
  return request.post(`/events/book `, data)
}

/**
 * 我参加的活动
 * @param {*} data
 */
export const events_mine = function(data) {
  return request.post(`/events/mine`, data)
}

/**
 * 活动的票
 * @param {*} data
 */
export const events_ticket = function(data) {
  return request.post(`/events/ticket`, data)
}

/**
 * 意见反馈
 * @param {*} data
 */
export const suggestion_add = function(data) {
  return request.post(`/suggestion/add`, data)
}

/**
 * 学生发票可申请列表
 * @param {*} data
 */
export const invoice_index = function(data) {
  return request.post(`/invoice/index`, data)
}

/**
 * 教师发票可申请列表
 * @param {*} data
 */
 export const employee_invoice = function(data) {
  return request.post(`/employee/invoice`, data)
}

/**
 * 默认发票信息
 * @param {*} data
 */
export const invoice_default = function(data) {
  return request.post(`/invoice/default`, data)
}

/**
 * 学生发票申请
 * @param {*} data
 */
export const invoice_apply = function(data) {
  return request.post(`/invoice/apply`, data)
}

/**
 * 教师发票申请
 * @param {*} data
 */
 export const employee_invoice_apply = function(data) {
  return request.post(`/employee/invoice-apply`, data)
}

/**
 * 积分商城
 * @param {*} data
 */
export const credits_index = function(data) {
  return request.post(`/credits/index`, data)
}

/**
 * 积分兑换
 * @param {*} data
 */
export const credits_exchange = function(data) {
  return request.post(`/credits/exchange`, data)
}

// .。。
// 表单收集
// .。。

// 学员服务

/**
 * 学员可请假课程列表
 * @param {*} data
 */
export const schedule_checklist = function(data) {
  return request.post(`/schedule/checklist`, data)
}
/**
 * 学员证书
 * @param {*} data
 */
export const certificate_index = function(data) {
  return request.post(`/certificate/index`, data)
}

/**
 * 学员成就
 * @param {*} data
 */
export const achievement_index = function(data) {
  return request.post(`/achievement/index`, data)
}

/**
 * 学员档案
 * @param {*} data
 */
export const message_index = function(data) {
  return request.post(`/message/index`, data)
}

export const message_like_or_cancel = function(data) {
  return request.post(`/message/like-or-cancel`, data)
}

/**
 * 学员作业
 * @param {*} data
 */
export const homework_index = function(data) {
  return request.post(`/homework/index`, data)
}

/**
 * 学员作业详情
 * @param {*} data
 */
export const homework_detail = function(data) {
  return request.post(`/homework/detail`, data)
}

/**
 * 课程购买
 * @param {*} data
 */
export const premium_index = function(data) {
  return request.post(`/premium/index`, data)
}

/**
 * 课程购买
 * @param {*} data
 */
 export const premium_consultant = function(data) {
  return request.post(`/premium/consultant`, data)
}

/**
 * 课程购买详情
 * @param {*} data
 */
export const premium_detail = function(data) {
  return request.post(`/premium/detail`, data)
}

/**
 * 学员协议
 * @param {*} data
 */
export const premium_agreement = function(data) {
  return request.post(`/premium/agreement`, data)
}

/**
 * 报读课程
 * @param {*} data
 */
export const contract_index = function(data) {
  return request.post(`/contract/index`, data)
}

/**
 * 报读课程
 * @param {*} data
 */
 export const contract_log = function(data) {
  return request.post(`/contract/log`, data)
}

/**
 * 我的活动记录
 * @param {*} data
 */
export const profile_events = function(data) {
  return request.post(`/profile/events`, data)
}

/**
 * 上课记录
 * @param {*} data
 */
export const profile_schedulelist = function(data) {
  return request.post(`/profile/schedule-list`, data)
}

// 预约试听

/**
 * 预约试听
 * @param {*} data
 */
export const audition_add = function(data) {
  return request.post(`/audition/add`, data)
}

export const audition_collect = function(data) {
  return request.post(`/audition/collect`, data)
}

export const audition_collect_clue = function(data) {
  return request.post(`/audition/collect-clue`, data)
}

// 在线招募

/**
 * 在线招募
 * @param {*} data
 */
export const recruit_index = function(data) {
  return request.post(`/recruit/index`, data)
}

/**
 * 在线招募详情
 * @param {*} data
 */
export const recruit_detail = function(data) {
  return request.post(`/recruit/detail`, data)
}

// 教师服务

/**
 * 老师课表
 * @param {*} data
 */
export const employee_schedule = function(data) {
  return request.post(`/employee/schedule`, data)
}

/**
 * 上课详情
 * @param {*} data
 */
export const employee_scheduledetail = function(data) {
  return request.post(`/employee/schedule-detail`, data)
}

/**
 * 上课详情
 * @param {*} data
 */
 export const employee_schedule_comment = function(data) {
  return request.post(`/employee/schedule-comment`, data)
}

/**
 * 上课详情
 * @param {*} data
 */
 export const employee_add_schedule_comment = function(data) {
  return request.post(`/employee/add-schedule-comment`, data)
}

/**
 * 上课详情
 * @param {*} data
 */
 export const employee_schedule_wait_for_comment = function(data) {
  return request.post(`/employee/schedule-wait-for-comment`, data)
}

/**
 * 上课详情
 * @param {*} data
 */
 export const employee_student = function(data) {
  return request.post(`/employee/student`, data)
}

/**
 * 上课学员签到
 * @param {*} data
 */
export const employee_scheduleAttend = function(data) {
  return request.post(`/employee/schedule-attend`, data)
}

/**
 * 上课学员签到(废除)
 * @param {*} data
 */
export const employee_schedulecheckin = function(data) {
  return request.post(`/employee/schedule-checkin`, data)
}

/**
 * 上课学员请假(废除)
 * @param {*} data
 */
export const employee_schedulecheckout = function(data) {
  return request.post(`/employee/schedule-checkout`, data)
}

/**
 * 学员上课成就
 * @param {*} data
 */
export const employee_scheduleachievement = function(data) {
  return request.post(`/employee/schedule-achievement`, data)
}

/**
 * 学员上课成就 点亮
 * @param {*} data
 */
export const employee_scheduleachievementtoggle = function(data) {
  return request.post(`/employee/schedule-achievement-toggle`, data)
}

/**
 * 老师开课记录/统计 图表
 * @param {*} data
 */
export const employee_scheduleanalysis = function(data) {
  return request.post(`/employee/schedule-analysis`, data)
}

/**
 * 老师上课记录
 * @param {*} data
 */
export const employee_schedulelist = function(data) {
  return request.post(`/employee/schedule-list`, data)
}

/**
 * 学员点评记录
 * @param {*} data
 */
export const employee_schedulewaitforcomment = function(data) {
  return request.post(`/employee/schedule-wait-for-comment`, data)
}

/**
 * 移除学员
 * @param {*} data
 */
 export const employee_schedule_remove_member = function(data) {
  return request.post(`/employee/schedule-remove-member`, data)
}

/**
 * DEMO排课
 * @param {*} data
 */
export const employee_demoaudition = function(data) {
  return request.post(`/employee/demo-audition`, data)
}

/**
 * 学员点评操作
 * @param {*} data
 */
export const employee_addbookcomment = function(data) {
  return request.post(`/employee/add-book-comment`, data)
}

/**
 * 作业批改
 * @param {*} data
 */
export const employee_homework = function(data) {
  return request.post(`/employee/homework`, data)
}

/**
 * 开班记录
 * @param {*} data
 */
export const employee_classes = function(data) {
  return request.post(`/employee/classes`, data)
}

/**
 * 班级详情
 * @param {*} data
 */
export const employee_classesdetail = function(data) {
  return request.post(`/employee/classes-detail`, data)
}

/**
 * 工资单
 * @param {*} data
 */
export const employee_salaryRoll= function(data) {
  return request.post(`/employee/salary-roll`, data)
}

/**
 * 工资单详情
 * @param {*} data
 */
export const employee_salaryBill= function(data) {
  return request.post(`/employee/salary-bill`, data)
}

/**
 * 机构中心
 * @param {*} data
 */
export const employee_index= function(data) {
  return request.post(`/employee/index`, data)
}

/**
 * 机构中心
 * @param {*} data
 */
export const employee_subordinate= function(data) {
  return request.post(`/employee/subordinate`, data)
}

/**
 * 添加临时插班学员
 * @param {*} data
 */
export const employee_schedule_add_member= function(data) {
  return request.post(`/employee/schedule-add-member`, data)
}

/**
 * 筛选学员
 * @param {*} data
 */
export const employee_search_students = function(data) {
  return request.get(`/employee/get-search-students`, {params:data})
}

// 机构服务

/**
 * 扫码签到
 * @param {*} data
 */
export const tools_scan = function(data) {
  return request.post(`/tools/scan`, data)
}

/**
 * 分享
 * @param {*} data
 */
export const tools_share = function(data) {
  return request.post(`/tools/share`, data)
}

/**
 * 分享
 * @param {*} data
 */
export const tools_poster = function(data) {
  return request.post(`/tools/poster`, data)
}

/**
 * 图表筛选校区数据
 * @param {*} data
 */
export const analysis_getsearchstudio = function(data) {
  return request.post(`/analysis/get-search-studio`, data)
}

/**
 * 销售线索数据统计-宫格
 * @param {*} data
 */
export const analysis_getleadscount = function(data) {
  return request.post(`/analysis/get-leads-count`, data)
}

/**
 * 销售线索数据统计
 * @param {*} data
 */
export const analysis_getleads = function(data) {
  return request.post(`/analysis/get-leads`, data)
}

/**
 * 客户跟进数据统计
 * @param {*} data
 */
export const analysis_getfollow = function(data) {
  return request.post(`/analysis/get-follow`, data)
}

/**
 * DEMO试听课数据统计-宫格
 * @param {*} data
 */
export const analysis_getdemocount = function(data) {
  return request.post(`/analysis/get-demo-count`, data)
}

/**
 * DEMO试听课数据统计
 * @param {*} data
 */
export const analysis_getdemo = function(data) {
  return request.post(`/analysis/get-demo`, data)
}

/**
 * 客户签约（新签/续签）数据统计 单数-宫格
 * @param {*} data
 */
export const analysis_getcontractcount = function(data) {
  return request.post(`/analysis/get-contract-count`, data)
}

/**
 * 客户签约（新签/续签）数据统计 单数
 * @param {*} data
 */
export const analysis_getcontract = function(data) {
  return request.post(`/analysis/get-contract`, data)
}

/**
 * 客户签约（新签/续签）数据统计 金额
 * @param {*} data
 */
export const analysis_getcontractincome = function(data) {
  return request.post(`/analysis/get-contract-income`, data)
}

/**
 * 客户退费数据统计 单数
 * @param {*} data
 */
export const analysis_getrefundcontract = function(data) {
  return request.post(`/analysis/get-refund-contract `, data)
}

/**
 * 客户退费数据统计 金额
 * @param {*} data
 */
export const analysis_getrefundcontractoutcome = function(data) {
  return request.post(`/analysis/get-refund-contract-outcome`, data)
}

/**
 * 排课数据统计 - 宫格
 * @param {*} data
 */
export const analysis_getschedulecount = function(data) {
  return request.post(`/analysis/get-schedule-count`, data)
}

/**
 * 排课数据统计
 * @param {*} data
 */
export const analysis_getschedule = function(data) {
  return request.post(`/analysis/get-schedule`, data)
}

/**
 * 上课学员统计
 * @param {*} data
 */
export const analysis_getbooking = function(data) {
  return request.post(`/analysis/get-booking`, data)
}

/**
 * 课时消耗统计
 * @param {*} data
 */
export const analysis_getcosts = function(data) {
  return request.post(`/analysis/get-costs`, data)
}

// 课程信息

/**
 * 课程信息
 * @param {*} data
 */
export const course_index = function(data) {
  return request.post(`/course/index`, data)
}

/**
 * 课程详情
 * @param {*} data
 */
export const course_detail = function(data) {
  return request.post(`/course/detail`, data)
}

/**
 * 课程课表
 * @param {*} data
 */
export const course_schedule = function(data) {
  return request.post(`/course/schedule`, data)
}

// 二维码扫码支付

/**
 * 二维码扫码付款
 * @param {*} data
 */
export const qrcodepayment_index = function(data) {
  return request.post(`/qrcode-payment/index`, data)
}

// --支付系统

/**
 * 微信支付 课程购买
 * @param {*} data
 */
export const payment_contract = function(data) {
  return request.post(`/payment/contract`, data)
}

/**
 * 微信支付 在线课程购买
 * @param {*} data
 */
export const payment_package = function(data) {
  return request.post(`/payment/package`, data)
}

/**
 * 订单支付
 * @param {*} data
 */
export const payment_store = function(data) {
  return request.post(`/payment/store`, data)
}

/**
 * 二维码支付
 * @param {*} data
 */
export const payment_qrcode = function(data) {
  return request.post(`/payment/qrcode`, data)
}

/**
 * 统一支付
 * @param {*} data
 */
 export const payment_order = function(data) {
  return request.post(`/payment/order`, data)
}

/**
 * 活动报名支付
 * @param {*} data
 */
export const payment_events = function(data) {
  return request.post(`/payment/events`, data)
}

/**
 * 钱包充值
 * @param {*} data
 */
 export const payment_reserve = function(data) {
  return request.post(`/payment/reserve`, data)
}

/**
 * 睿艺活动报名支付
 * @param {*} data
 */
export const ruiyi_ace_payment = function(data) {
  return request.post(`/custom/ruiyi/ace/payment`, data)
}

/**
 * 睿艺活动报名支付
 * @param {*} data
 */
export const ruiyi_ace_pay = function(data) {
  return request.post(`/custom/ruiyi/ace/pay`, data)
}

/**
 * 在线招募支付
 * @param {*} data
 */
export const payment_recruit = function(data) {
  return request.post(`/payment/recruit`, data)
}


// --注册登录
/**
 * 注册登录
 * @param {*} data
 */
export const auth_index = function(data) {
  return request.post(`/auth/index`, data)
}

/**
 * 微信公众号授权
 * @param {*} data
 */
export const auth_wechat = function(data) {
  return request.post(`/auth/wechat`, data)
}

/**
 * 微信公众号授权
 * @param {*} data
 */
 export const auth_open_info = function(data) {
  return request.post(`/auth/open-info`, data)
}

/**
 * 企业微信授权
 * @param {*} data
 */
export const qywx_auth_wechat = function(data) {
  return request.post(`/qywx/auth/wechat`, data)
}

/**
 * 微信公众号授权跳转地址
 * @param {*} data
 */
export const auth_redirect = function(data) {
  return request.post(`/auth/redirect`, data)
}

/**
 * 微信公众号授权跳转地址
 * @param {*} data
 */
export const qywx_auth_redirect = function(data) {
  return request.post(`/qywx/auth/redirect`, data)
}

/**
 * 发送短信验证码
 * @param {*} data
 */
export const auth_send_text_msg = function(data) {
  return request.post(`/auth/send-text-msg`, data)
}

// --消息通知
/**
 * 消息通知
 * @param {*} data
 */
export const notice_index = function(data) {
  return request.post(`/notice/index`, data)
}

/**
 * 消息详情
 * @param {*} data
 */
export const notice_detail = function(data) {
  return request.post(`/notice/detail`, data)
}

/**
 * 表单详情
 * @param {*} data
 */
export const survey_detail = function(data) {
  return request.post(`/survey/detail`, data)
}

/**
 * 表单详情
 * @param {*} data
 */
export const survey_send_text_msg = function(data) {
  return request.post(`/survey/send-text-msg`, data)
}

/**
 * 表单提交
 * @param {*} data
 */
export const survey_submit = function(data) {
  return request.post(`/survey/submit`, data)
}

/**
 * 慧动表单详情
 * @param {*} data
 */
export const custom_huidong_form = function(data) {
  return request.post(`/custom/huidong/huidong/form`, data)
}

/**
 * 表单详情
 * @param {*} data
 */
 export const crm_contract_form = function(data) {
  return request.post(`/crm/contract/form`, data)
}

/**
 * 教师表单详情
 * @param {*} data
 */
 export const crm_employee_form = function(data) {
  return request.post(`/crm/employee/employee-form`, data)
}

/**
 * 慧动教师表单详情
 * @param {*} data
 */
export const custom_huidong_employee_form = function(data) {
  return request.post(`/custom/huidong/huidong-employee/employee-form`, data)
}

/**
 * 表单信息
 * @param {*} data
 */
export const crm_contract_detail = function(data) {
  return request.post(`/crm/contract/detail`, data)
}

/**
 * 慧动表单信息
 * @param {*} data
 */
 export const custom_huidong_detail = function(data) {
  return request.post(`/custom/huidong/huidong/detail`, data)
}

/**
 * 表单提交
 * @param {*} data
 */
export const crm_contract_apply = function(data) {
  return request.post(`/crm/contract/apply`, data)
}

/**
 * 慧动表单提交
 * @param {*} data
 */
 export const custom_huidong_apply = function(data) {
  return request.post(`/custom/huidong/huidong/apply`, data)
}

/**
 * 表单提交
 * @param {*} data
 */
export const crm_contract_signature = function(data) {
  return request.post(`/crm/contract/signature`, data)
}

/**
 * 慧动表单提交
 * @param {*} data
 */
 export const custom_huidong_signature = function(data) {
  return request.post(`/custom/huidong/huidong/signature`, data)
}

/**
 * 表单支付
 * @param {*} data
 */
export const crm_contract_payment = function(data) {
  return request.post(`/crm/contract/payment`, data)
}

/**
 * 慧动表单支付
 * @param {*} data
 */
 export const custom_huidong_payment = function(data) {
  return request.post(`/custom/huidong/huidong/payment`, data)
}

/**
 * 周相册
 * @param {*} data
 */
 export const custom_huidong_week_print = function(data) {
  return request.post(`/custom/huidong/huidong/week-print`, data)
}

/**
 * 添加打印
 * @param {*} data
 */
 export const custom_huidong_add_print = function(data) {
  return request.post(`/custom/huidong/huidong/add-print`, data)
}

/**
 * 教师表单提交
 * @param {*} data
 */
 export const crm_create_apply = function(data) {
  return request.post(`/crm/employee/create-apply`, data)
}

/**
 * 慧动教师表单提交
 * @param {*} data
 */
export const custom_huidong_create_apply = function(data) {
  return request.post(`/custom/huidong/huidong-employee/create-apply`, data)
}

/**
 * 慧动教师表单详情
 * @param {*} data
 */
export const custom_huidong_apply_detail = function(data) {
  return request.post(`/custom/huidong/huidong-employee/apply-detail`, data)
}

/**
 * 教师表单详情
 * @param {*} data
 */
 export const crm_employee_apply_detail = function(data) {
  return request.post(`/crm/employee/apply-detail`, data)
}

/**
 * 购买课程
 * @param {*} data
 */
 export const crm_fee = function(data) {
  return request.post(`/crm/employee/fee`, data)
}

/**
 * 慧动购买课程
 * @param {*} data
 */
export const custom_huidong_fee = function(data) {
  return request.post(`/custom/huidong/huidong-employee/fee`, data)
}

/**
 * 优惠券
 * @param {*} data
 */
 export const crm_fee_coupons = function(data) {
  return request.post(`/crm/employee/fee-coupons`, data)
}

/**
 * 慧动优惠券
 * @param {*} data
 */
export const custom_huidong_fee_coupons = function(data) {
  return request.post(`/custom/huidong/huidong-employee/fee-coupons`, data)
}

/**
 * 慧动邀约到访
 * @param {*} data
 */
export const custom_huidong_invited = function(data) {
  return request.post(`/custom/huidong/huidong-employee/invited`, data)
}

/**
 * 邀约到访
 * @param {*} data
 */
 export const crm_employee_invited = function(data) {
  return request.post(`/crm/employee/invited`, data)
}

/**
 * 慧动邀约到访(确认到访)
 * @param {*} data
 */
export const custom_huidong_attend = function(data) {
  return request.post(`/custom/huidong/huidong-employee/attend`, data)
}

/**
 * 邀约到访(确认到访)
 * @param {*} data
 */
 export const crm_employee_attend = function(data) {
  return request.post(`/crm/employee/attend`, data)
}

/**
 * 慧动邀约到访(鸽子)
 * @param {*} data
 */
export const custom_huidong_no_show = function(data) {
  return request.post(`/custom/huidong/huidong-employee/no-show`, data)
}

/**
 * 邀约到访(鸽子)
 * @param {*} data
 */
 export const crm_employee_no_show = function(data) {
  return request.post(`/crm/employee/no-show`, data)
}

/**
 * 邀约到访日历红点
 * @param {*} data
 */
export const custom_huidong_invited_schedule = function(data) {
  return request.post(`/custom/huidong/huidong-employee/invited-schedule`, data)
}

/**
 * 邀约到访日历红点
 * @param {*} data
 */
 export const crm_employee_invited_schedule = function(data) {
  return request.post(`/crm/employee/invited-schedule`, data)
}

/**
 * 老师查询
 * @param {*} data
 */
 export const custom_huidong_campus_teacher = function(data) {
  return request.post(`/custom/huidong/huidong/campus-teacher`, data)
}

/**
 * 班级查询
 * @param {*} data
 */
 export const custom_huidong_campus_classes = function(data) {
  return request.post(`/custom/huidong/huidong/campus-classes`, data)
}

/**
 * 报读班级
 * @param {*} data
 */
 export const custom_huidong_join_classes = function(data) {
  return request.post(`/custom/huidong/huidong/join-classes`, data)
}


/**
 * 课程查询
 * @param {*} data
 */
 export const custom_huidong_campus_schedule = function(data) {
  return request.post(`/custom/huidong/huidong/campus-schedule`, data)
}

/**
 * 课程查询
 * @param {*} data
 */
 export const custom_huidong_campus_schedule_detail = function(data) {
  return request.post(`/custom/huidong/huidong/campus-schedule-detail`, data)
}

/**
 * 约课日期
 * @param {*} data
 */
 export const custom_huidong_campus_available_schedule = function(data) {
  return request.post(`/custom/huidong/huidong/campus-available-schedule`, data)
}

/**
 * 约课日期
 * @param {*} data
 */
 export const custom_huidong_available_studios = function(data) {
  return request.post(`/custom/huidong/huidong/available-studios`, data)
}

export const custom_huidong_available_schedule_date = function(data) {
  return request.post(`/custom/huidong/huidong/available-schedule-date`, data)
}

/**
 * 约课
 * @param {*} data
 */
 export const custom_huidong_join_courses = function(data) {
  return request.post(`/custom/huidong/huidong/join-courses`, data)
}

/**
 * 约课
 * @param {*} data
 */
 export const custom_join_courses = function(data) {
  return request.post(`/appointment/join-courses`, data)
}

/**
 * 约课日期
 * @param {*} data
 */
 export const custom_available_studios = function(data) {
  return request.post(`/appointment/available-studios`, data)
}

export const custom_available_schedule_date = function(data) {
  return request.post(`/appointment/available-schedule-date`, data)
}

/**
 * 老师查询
 * @param {*} data
 */
 export const custom_campus_teacher = function(data) {
  return request.post(`/appointment/campus-teacher`, data)
}

/**
 * 老师查询
 * @param {*} data
 */
 export const custom_campus_schedule = function(data) {
  return request.post(`/appointment/campus-schedule`, data)
}
/**
 * 课程查询
 * @param {*} data
 */
 export const custom_campus_schedule_detail = function(data) {
  return request.post(`/appointment/campus-schedule-detail`, data)
}
/**
 * 约课日期
 * @param {*} data
 */
 export const custom_campus_available_schedule = function(data) {
  return request.post(`/appointment/campus-available-schedule`, data)
}

/**
 * h5判断有没有支付宝商户
 * @param {*} data
 */
export const h5_payment_type = function(data) {
  return request.post(`/payment/type`, data)
}


/**
 * 邀约到访
 * @param {*} data
 */
export const crm_analysis_follows = function(data) {
  return request.post(`/crm/analysis/follows`, data)
}

/**
 * TMK
 * @param {*} data
 */
export const crm_analysis_tmk = function(data) {
  return request.post(`/crm/analysis/tmk`, data)
}

/**
 * TMK-list
 * @param {*} data
 */
export const crm_analysis_tmk_list = function(data) {
  return request.post(`/crm/analysis/tmk-list`, data)
}

/**
 * 日报
 * @param {*} data
 */
export const crm_analysis_today = function(data) {
  return request.post(`/crm/analysis/today`, data)
}

/**
 * 客户列表(-)
 * @param {*} data
 */
export const crm_customer_attend = function(data) {
  return request.post(`/crm/customer/attend`, data)
}

/**
 * 客户列表
 * @param {*} data
 */
export const crm_customer_index = function(data) {
  return request.post(`/crm/customer/index`, data)
}

/**
 * 客户列表
 * @param {*} data
 */
 export const crm_customer_call = function(data) {
  return request.post(`/crm/customer/call`, data)
}

/**
 * 慧动客户列表
 * @param {*} data
 */
 export const custom_huidong_customer_index = function(data) {
  return request.post(`/custom/huidong/customer/index`, data)
}

/**
 * 添加客户
 * @param {*} data
 */
export const crm_customer_add = function(data) {
  return request.post(`/crm/customer/add`, data)
}

/**
 * 慧动添加客户
 * @param {*} data
 */
 export const custom_huidong_customer_add = function(data) {
  return request.post(`/custom/huidong/customer/add`, data)
}

/**
 * 客户详情
 * @param {*} data
 */
export const crm_customer_detail = function(data) {
  return request.post(`/crm/customer/detail`, data)
}

/**
 * 慧动客户详情
 * @param {*} data
 */
 export const custom_huidong_customer_detail = function(data) {
  return request.post(`/custom/huidong/customer/detail`, data)
}

/**
 * 客户跟进记录编辑
 * @param {*} data
 */
export const crm_customer_follow = function(data) {
  return request.post(`/crm/customer/follow`, data)
}

/**
 * 慧动客户跟进记录编辑
 * @param {*} data
 */
 export const custom_huidong_customer_follow = function(data) {
  return request.post(`/custom/huidong/customer/follow`, data)
}

/**
 * 客户跟进记录
 * @param {*} data
 */
export const crm_customer_follow_log = function(data) {
  return request.post(`/crm/customer/follow-log`, data)
}

/**
 * 获取试听排期
 * @param {*} data
 */
 export const crm_customer_audition_schedule = function(data) {
  return request.post(`/crm/customer/audition-schedule`, data)
}

/**
 * 试听排期
 * @param {*} data
 */
 export const crm_customer_audition_arrange = function(data) {
  return request.post(`/crm/customer/audition-arrange`, data)
}

/**
 * 取消试听排期
 * @param {*} data
 */
 export const crm_customer_audition_cancel = function(data) {
  return request.post(`/crm/customer/audition-cancel`, data)
}

/**
 * 慧动客户跟进记录
 * @param {*} data
 */
 export const custom_huidong_customer_follow_log = function(data) {
  return request.post(`/custom/huidong/customer/follow-log`, data)
}

/**
 * 客户跟进记录
 * @param {*} data
 */
export const crm_customer_update = function(data) {
  return request.post(`/crm/customer/update`, data)
}

/**
 * 客户转移
 * @param {*} data
 */
export const crm_customer_transfer = function(data) {
  return request.post(`/crm/customer/transfer`, data)
}

/**
 * 慧动客户转移
 * @param {*} data
 */
 export const custom_huidong_customer_transfer = function(data) {
  return request.post(`/custom/huidong/customer/transfer`, data)
}

/**
 * 客户状态跟新
 * @param {*} data
 */
export const crm_customer_task_update = function(data) {
  return request.post(`/crm/customer/task-update`, data)
}

/**
 * 慧动客户状态跟新
 * @param {*} data
 */
 export const custome_huidong_task_update = function(data) {
  return request.post(`/custom/huidong/customer/task-update`, data)
}

/**
 * 线索列表
 * @param {*} data
 */
export const crm_leads_index = function(data) {
  return request.post(`/crm/leads/index`, data)
}

/**
 * 线索列表
 * @param {*} data
 */
 export const crm_leads_call = function(data) {
  return request.post(`/crm/leads/call`, data)
}

/**
 * 慧动线索列表
 * @param {*} data
 */
 export const custom_huidong_leads_index = function(data) {
  return request.post(`/custom/huidong/leads/index`, data)
}

/**
 * 线索详情
 * @param {*} data
 */
 export const crm_leads_detail = function(data) {
  return request.post(`/crm/leads/detail`, data)
}

/**
 * 慧动线索详情
 * @param {*} data
 */
 export const custom_huidong_leads_detail = function(data) {
  return request.post(`/custom/huidong/leads/detail`, data)
}

/**
 * 线索跟进记录编辑
 * @param {*} data
 */
export const crm_leads_follow = function(data) {
  return request.post(`/crm/leads/follow`, data)
}

/**
 * 慧动线索跟进记录编辑
 * @param {*} data
 */
 export const custom_huidong_leads_follow = function(data) {
  return request.post(`/custom/huidong/leads/follow`, data)
}

/**
 * 线索跟进
 * @param {*} data
 */
 export const crm_leads_follow_log = function(data) {
  return request.post(`/crm/leads/follow-log`, data)
}

/**
 * 慧动线索跟进
 * @param {*} data
 */
 export const custom_huidong_leads_follow_log = function(data) {
  return request.post(`/custom/huidong/leads/follow-log`, data)
}

/**
 * 线索列表
 * @param {*} data
 */
export const crm_leads_transform = function(data) {
  return request.post(`/crm/leads/transform`, data)
}

/**
 * 慧动线索列表
 * @param {*} data
 */
 export const custom_huidong_leads_transform = function(data) {
  return request.post(`/custom/huidong/leads/transform`, data)
}

/**
 * 线索转移
 * @param {*} data
 */
export const crm_leads_transfer = function(data) {
  return request.post(`/crm/leads/transfer`, data)
}

/**
 * 慧动线索转移
 * @param {*} data
 */
 export const custom_huidong_leads_transfer = function(data) {
  return request.post(`/custom/huidong/leads/transfer`, data)
}

/**
 * 线索状态跟新
 * @param {*} data
 */
export const crm_leads_task_update = function(data) {
  return request.post(`/crm/leads/task-update`, data)
}

/**
 * 慧动线索状态跟新
 * @param {*} data
 */
 export const custom_huidong_leads_task_update = function(data) {
  return request.post(`/custom/huidong/leads/task-update`, data)
}

/**
 * 所属校区
 * @param {*} data
 */
export const crm_filter_stduio = function(data) {
  return request.post(`/crm/filter/get-belong-studio`, data)
}

/**
 * 意向课程
 * @param {*} data
 */
export const crm_filter_course = function(data) {
  return request.post(`/crm/filter/get-course-type`, data)
}

/**
 * 来源渠道
 * @param {*} data
 */
export const crm_filter_channel = function(data) {
  return request.post(`/crm/filter/get-cascader-channel`, data)
}

/**
 * 员工列表
 * @param {*} data
 */
 export const crm_filter_employee = function(data) {
  return request.post(`/crm/filter/get-search-employee`, data)
}

/**
 * 员工列表
 * @param {*} data
 */
 export const crm_filter_get_cascader_employee = function(data) {
  return request.post(`/crm/filter/get-cascader-employee`, data)
}

/**
 * 业绩统计
 * @param {*} data
 */
export const crm_commision = function(data) {
  return request.post(`/crm/commision/index`, data)
}

/**
 * 慧动业绩统计
 * @param {*} data
 */
 export const custom_huidong_commision = function(data) {
  return request.post(`/custom/huidong/commision/index`, data)
}


/**
 * 点击事件记录
 * @param {*} data 
 * @returns 
 */
export const click_index= function(data) {
  return request.post(`/click/index`, data)
}


/**
 * 筑桥zhupio
 * @param {*} data
 */
export const recruit_zhuqiao= function(data) {
  return request.post(`/custom/zhuqiao/recruit/index`, data)
}

/**
 * 筑桥退费申请
 * @param {*} data
 */
 export const zhuqiao_refund_apply_form= function(data) {
  return request.post(`/custom/zhuqiao/refund-apply/form`, data)
}

/**
 * 筑桥退费表单提交
 * @param {*} data
 */
 export const zhuqiao_refund_apply_apply= function(data) {
  return request.post(`/custom/zhuqiao/refund-apply/apply`, data)
}

/**
 * 员工日报
 * @param {*} data
 */
export const crm_report_employee= function(data) {
  return request.post(`/crm/report/employee`, data)
}

/**
 * 慧动员工日报
 * @param {*} data
 */
 export const custom_huidong_report_employee= function(data) {
  return request.post(`/custom/huidong/report/employee`, data)
}

/**
 * 员工周报
 * @param {*} data
 */
export const crm_report_employee_week= function(data) {
  return request.post(`/crm/report/employee-week`, data)
}

/**
 * 慧动员工周报
 * @param {*} data
 */
 export const custom_huidong_report_employee_week= function(data) {
  return request.post(`/custom/huidong/report/employee-week`, data)
}

/**
 * 校区日报
 * @param {*} data
 */
export const crm_report_studio= function(data) {
  return request.post(`/crm/report/studio`, data)
}

/**
 * 慧动校区日报
 * @param {*} data
 */
 export const custom_huidong_report_studio= function(data) {
  return request.post(`/custom/huidong/report/studio`, data)
}

/**
 * 日报员工列表
 * @param {*} data
 */
export const custom_huidong_journal= function(data) {
  return request.post(`/custom/huidong/journal/index`, data)
}

/**
 * 日报详情
 * @param {*} data
 */
export const custom_huidong_journal_list= function(data) {
  return request.post(`/custom/huidong/journal/list`, data)
}

/**
 * 日报详情-已读
 * @param {*} data
 */
export const custom_huidong_journal_seen= function(data) {
  return request.post(`/custom/huidong/journal/seen`, data)
}

/**
 * 日报详情
 * @param {*} data
 */
export const custom_huidong_journal_add_comment= function(data) {
  return request.post(`/custom/huidong/journal/add-comment`, data)
}

/**
 * 校区周报
 * @param {*} data
 */
export const crm_report_studio_week= function(data) {
  return request.post(`/crm/report/studio-week`, data)
}

/**
 * 慧动校区周报
 * @param {*} data
 */
 export const custom_huidong_report_studio_week= function(data) {
  return request.post(`/custom/huidong/report/studio-week`, data)
}

/**
 * 数据报表-市场数据统计
 * @param {*} data
 */
export const analysis_campus_mkt= function(data) {
  return request.post(`/analysis/campus/mkt`, data)
}

/**
 * 数据报表-课时消耗统计
 * @param {*} data
 */
export const analysis_campus_course= function(data) {
  return request.post(`/analysis/campus/course`, data)
}

/**
 * 数据报表-签约数据统计
 * @param {*} data
 */
export const analysis_campus_contract= function(data) {
  return request.post(`/analysis/campus/contract`, data)
}

/**
 * 数据报表-CRM数据统计
 * @param {*} data
 */
export const analysis_campus_crm= function(data) {
  return request.post(`/analysis/campus/crm`, data)
}

/**
 * 数据报表-CRM业绩排行
 * @param {*} data
 */
export const analysis_campus_ranking= function(data) {
  return request.post(`/analysis/campus/ranking`, data)
}

/**
 * 数据报表-CRM业绩排行
 * @param {*} data
 */
export const analysis_campus_teacher= function(data) {
  return request.post(`/analysis/campus/teacher`, data)
}

/**
 * 会话事件
 * @param {*} data
 */
 export const book_events_index= function(data) {
  return request.post(`/qywx/msg-book/index`, data)
}

/**
 * 企业话术
 * @param {*} data
 */
export const discourse_index= function(data) {
  return request.post(`/crm/discourse/index`, data)
}

export const discourse_leads= function(data) {
  return request.post(`/crm/discourse/leads`, data)
}

export const discourse_customer= function(data) {
  return request.post(`/crm/discourse/customer`, data)
}

/**
 * 企业话术-刷新
 * @param {*} data
 */
export const discourse_refresh= function(data) {
  return request.post(`/crm/discourse/refresh`, data)
}

/**
 * 企业话术-点击
 * @param {*} data
 */
export const discourse_click= function(data) {
  return request.post(`/crm/discourse/click`, data)
}

/**
 * 企业微信-聊天记录
 * @param {*} data
 */
export const qywx_msg_detail= function(data) {
  return request.post(`/qywx/msg/detail`, data)
}

/**
 * 企业微信-标签
 * @param {*} data
 */
export const qywx_tag_index= function(data) {
  return request.post(`/qywx/tag/index`, data)
}

/**
 * 企业微信-客户画像-群
 * @param {*} data
 */
export const qywx_external_group_profile= function(data) {
  return request.post(`/qywx/external-group/profile`, data)
}

/**
 * 企业微信-客户画像-群
 * @param {*} data
 */
 export const qywx_external_message= function(data) {
  return request.post(`/qywx/external-contact/message`, data)
}

/**
 * 企业微信-客户画像-群
 * @param {*} data
 */
 export const qywx_external_add_message= function(data) {
  return request.post(`/qywx/external-contact/add-message`, data)
}

/**
 * 企业微信-客户画像-跟进状态
 * @param {*} data
 */
export const qywx_external_task_update= function(data) {
  return request.post(`/qywx/external-contact/task-update`, data)
}

/**
 * 企业微信-客户画像-跟进内容
 * @param {*} data
 */
export const qywx_external_follow= function(data) {
  return request.post(`/qywx/external-contact/follow`, data)
}

/**
 * 企业微信-客户画像-跟进状态
 * @param {*} data
 */
export const qywx_external_follow_log= function(data) {
  return request.post(`/qywx/external-contact/follow-log`, data)
}

/**
 * 企业微信-客户画像-群成员
 * @param {*} data
 */
export const qywx_external_group_members= function(data) {
  return request.post(`/qywx/external-group/members`, data)
}

/**
 * 企业微信-客户画像-群成员-可关联班级
 * @param {*} data
 */
 export const qywx_external_group_can_related_class= function(data) {
  return request.post(`/qywx/external-group/can-related-class`, data)
}

/**
 * 企业微信-客户画像-群成员-关联班级
 * @param {*} data
 */
 export const qywx_external_group_related_class= function(data) {
  return request.post(`/qywx/external-group/related-class`, data)
}

/**
 * 企业微信-客户画像-群成员-开班记录
 * @param {*} data
 */
 export const classes_schedule= function(data) {
  return request.post(`/classes/schedule`, data)
}

/**
 * 企业微信-客户画像-个人
 * @param {*} data
 */
export const qywx_external_contact_index= function(data) {
  return request.post(`/qywx/external-contact/index`, data)
}

/**
 * 企业微信-客户画像-邀请
 * @param {*} data
 */
export const qywx_external_contact_invited= function(data) {
  return request.post(`/qywx/external-contact/invited`, data)
}

/**
 * 企业微信-客户画像-上课记录
 * @param {*} data
 */
export const qywx_external_contact_course= function(data) {
  return request.post(`/qywx/external-contact/course`, data)
}

/**
 * 企业微信-客户画像-绑定学员
 * @param {*} data
 */
export const qywx_external_contact_bind= function(data) {
  return request.post(`/qywx/external-contact/bind`, data)
}

/**
 * 企业微信-客户画像-学员自动识别
 * @param {*} data
 */
export const qywx_external_contact_recognize= function(data) {
  return request.post(`/qywx/external-contact/recognize`, data)
}



/**
 * 企业微信-客户画像-个人
 * @param {*} data
 */
export const qywx_external_contact_profile= function(data) {
  return request.post(`/qywx/external-contact/profile`, data)
}

/**
 * 企业微信-客户画像-个人名片
 * @param {*} data
 */
export const qywx_external_contact_contact_way= function(data) {
  return request.post(`/qywx/external-contact/contact-way`, data)
}

/**
 * 企业微信-客户画像-个人名片
 * @param {*} data
 */
export const qywx_external_contact_contact_base64= function(data) {
  return request.post(`/qywx/external-contact/contact-qrcode-base64`, data)
}


/**
 * 活动二维码
 * @param {*} data
 */
 export const namecard_index= function(data) {
  return request.get(`/namecard/index`, {params:data})
}

/**
 * 活动二维码
 * @param {*} data
 */
export const events_qrcode= function(data) {
  return request.get(`/events/qrcode`, {params:data})
}

/**
 * 日活跃
 * @param {*} data
 */
export const daily_active= function(data) {
  return request.get(`/profile/daily-active`, {params:data})
}

/**
 * 机构注册
 * @param {*} data
 */
 export const apply_index= function(data) {
  return request.post(`/apply/index`, data)
}

/**
 * 机构注册
 * @param {*} data
 */
 export const apply_terms= function(data) {
  return request.post(`/apply/terms`, data)
}

/**
 * 公告详情
 * @param {*} data
 */
 export const oa_announce_detail= function(data) {
  return request.post(`/oa/announce/detail`, data)
}

/**
 * 公告详情
 * @param {*} data
 */
 export const oa_announce_seen= function(data) {
  return request.post(`/oa/announce/seen`, data)
}


/**
 * 审批
 * @param {*} data
 */
 export const oa_examine= function(data) {
  return request.post(`/oa/examine/index`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_detail= function(data) {
  return request.post(`/oa/examine/detail`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_category= function(data) {
  return request.post(`/oa/examine/category`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_add= function(data) {
  return request.post(`/oa/examine/add`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_update= function(data) {
  return request.post(`/oa/examine/update`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_check= function(data) {
  return request.post(`/oa/examine/check`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_check_record= function(data) {
  return request.post(`/oa/examine/check-record`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_step= function(data) {
  return request.post(`/oa/examine/step`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const oa_examine_revoke= function(data) {
  return request.post(`/oa/examine/revoke`, data)
}


/**
 * 审批
 * @param {*} data
 */
 export const ps_receive_index= function(data) {
  return request.post(`/receive/index`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const ps_receive_request= function(data) {
  return request.post(`/receive/request`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const ps_deliver_index= function(data) {
  return request.post(`/deliver/index`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const ps_deliver_detail= function(data) {
  return request.post(`/deliver/detail`, data)
}

/**
 * 审批
 * @param {*} data
 */
 export const ps_deliver_finish= function(data) {
  return request.post(`/deliver/finish`, data)
}

export default {
  ps_receive_index,
  ps_receive_request,
  ps_deliver_index,
  ps_deliver_detail,
  ps_deliver_finish,

  // 退出登录
  auth_logout,
  // 学员中心
  profile_index,
  profile_update,
  profile_credits,
  profile_switch,
  profile_namecard,
  profile_campus,
  // 关联家属
  profile_contact,
  profile_wallet,
  profile_classes,
  profile_order_list,
  profile_add_contact,
  profile_add_kids,
  profile_contact_detail,
  profile_update_contact,
  profile_del_contact,
  // 通用筛选
  filter_studios,
  filter_district,
  filter_districtstudio,
  filter_courseTeacher,
  recruit_filter,
  // 焦点图
  banner_index,
  // 系统信息
  brand_index,
  // 老师信息
  teacher_detail,
  teacher_schedule,
  teacher_comment,
  // upload
  upload_image,
  // JSSDK
  jssdk_index,
  // 企业微信JSSDK
  qywx_jssdk_index,
  qywx_jssdk_upload_media,
  // 校区列表
  campus_index,
  campus_detail,
  campus_schedule,
  campus_monitor,
  campus_tabbar,

  // tax
  tax_index,
  // 2899弄Cai
  cai_cat,
  cai_cart,
  cai_receive,
  cai_finish,
  cai_del_cart,
  cai_add_cart,
  cai_order,
  cai_list,

  // 在线商城
  store_cat,
  store_index,
  store_product,
  store_productcomment,
  // 购物车
  cart_index,
  cart_get,
  cart_set,
  cart_del,
  cart_reduce,
  cart_count,
  cart_clear,
  // 收货地址
  address_index,
  address_add,
  address_delete,
  address_default,
  address_detail,
  address_update,
  // 我的优惠券
  coupon_index,
  coupon_active,
  coupon_exchange,
  // 商城订单
  order_index,
  order_createorder,
  order_detail,
  order_cancelorder,
  order_express,
  order_totalcanrefund,
  order_refund,
  order_comment,
  order_addcomment,
  // 其他接口未完成
  // 助学工具
  content_index,
  content_packagedetail,
  content_packagecontent,
  content_detail,
  content_play,
  content_ppt,
  content_comment,
  content_addcomment,
  events_index,
  events_detail,
  events_book,
  events_mine,
  events_ticket,
  suggestion_add,
  invoice_index,
  employee_invoice,
  invoice_default,
  invoice_apply,
  employee_invoice_apply,
  credits_index,
  credits_exchange,
  // 学员服务
  schedule_checklist,
  certificate_index,
  achievement_index,
  message_index,
  message_like_or_cancel,
  homework_index,
  homework_detail,
  premium_index,
  premium_consultant,
  premium_detail,
  premium_agreement,
  contract_index,
  contract_log,
  profile_events,
  profile_schedulelist,
  // 预约试听
  audition_add,
  audition_collect,
  audition_collect_clue,
  // 学员课表
  schedule_index,
  schedule_detail,
  schedule_waitlist,
  schedule_booklist,
  schedule_cancel_wait,
  schedule_live,
  schedule_booking,
  schedule_feedback,
  schedule_waitforcomment,
  schedule_comment,
  schedule_like_or_cancel,
  schedule_addcomment,
  schedule_summary,
  schedule_checkout,
  // 教师服务
  employee_schedule,
  employee_scheduledetail,
  employee_schedule_comment,
  employee_add_schedule_comment,
  employee_schedule_wait_for_comment,
  employee_student,
  employee_scheduleAttend,
  employee_schedulecheckin,
  employee_schedulecheckout,
  employee_scheduleachievement,
  employee_scheduleachievementtoggle,
  employee_scheduleanalysis,
  employee_schedulelist,
  employee_schedulewaitforcomment,
  employee_schedule_remove_member,
  employee_demoaudition,
  employee_addbookcomment,
  employee_homework,
  employee_classes,
  employee_classesdetail,
  employee_salaryRoll,
  employee_salaryBill,
  employee_index,
  employee_subordinate,
  employee_schedule_add_member,
  employee_search_students,
  // 机构服务
  tools_scan,
  tools_share,
  tools_poster,
  analysis_getsearchstudio,
  analysis_getleadscount,
  analysis_getleads,
  analysis_getfollow,
  analysis_getdemocount,
  analysis_getdemo,
  analysis_getcontractcount,
  analysis_getcontract,
  analysis_getcontractincome,
  analysis_getrefundcontract,
  analysis_getrefundcontractoutcome,
  analysis_getschedulecount,
  analysis_getschedule,
  analysis_getbooking,
  analysis_getcosts,
  // 课程信息
  course_index,
  course_detail,
  // 二维码扫码付款
  qrcodepayment_index,
  // 支付系统
  payment_contract,
  payment_store,
  payment_qrcode,
  payment_order,
  payment_events,
  payment_reserve,
  ruiyi_ace_pay,
  ruiyi_ace_payment,
  payment_recruit,
  payment_package,
  // 注册登录
  auth_index,
  auth_wechat,
  auth_open_info,
  qywx_auth_wechat,
  auth_redirect,
  qywx_auth_redirect,
  auth_send_text_msg,
  // 注册登录
  notice_index,
  notice_detail,
  // 表单详情
  survey_detail,
  survey_submit,
  survey_send_text_msg,
  custom_huidong_form,
  crm_contract_form,
  crm_employee_form,
  custom_huidong_employee_form,
  custom_huidong_detail,
  crm_contract_detail,
  custom_huidong_apply,
  crm_contract_apply,
  custom_huidong_signature,
  crm_contract_signature,
  custom_huidong_payment,
  crm_contract_payment,
  custom_huidong_week_print,
  custom_huidong_add_print,
  crm_fee,
  custom_huidong_fee,
  crm_fee_coupons,
  custom_huidong_fee_coupons,
  custom_huidong_invited,
  crm_employee_invited,
  custom_huidong_invited_schedule,
  crm_employee_invited_schedule,
  custom_huidong_campus_teacher,
  custom_huidong_campus_classes,
  custom_huidong_join_classes,
  custom_huidong_campus_schedule,
  custom_huidong_campus_schedule_detail,
  custom_huidong_campus_available_schedule,
  custom_huidong_available_studios,
  custom_huidong_available_schedule_date,
  custom_huidong_join_courses,
  custom_join_courses,
  custom_available_studios,
  custom_available_schedule_date,
  custom_campus_teacher,
  custom_campus_schedule,
  custom_campus_schedule_detail,
  custom_campus_available_schedule,
  crm_create_apply,
  custom_huidong_create_apply,
  custom_huidong_apply_detail,
  crm_employee_apply_detail,
  custom_huidong_attend,
  crm_employee_attend,
  custom_huidong_no_show,
  crm_employee_no_show,

  //点击事件
  click_index,

  // 招募详情
  recruit_index,
  recruit_detail,
  // h5判断有没有支付宝商户
  h5_payment_type,
  // 招生报表
  crm_analysis_follows,
  crm_customer_attend,
  crm_analysis_tmk,
  crm_analysis_tmk_list,
  crm_analysis_today,
  // zhuqiao
  recruit_zhuqiao,
  zhuqiao_refund_apply_form,
  zhuqiao_refund_apply_apply,
  // 日报
  crm_report_employee,
  custom_huidong_report_employee,
  crm_report_employee_week,
  custom_huidong_report_employee_week,
  custom_huidong_report_studio,
  crm_report_studio,
  custom_huidong_report_studio_week,
  crm_report_studio_week,
  custom_huidong_journal,
  custom_huidong_journal_list,
  custom_huidong_journal_seen,
  custom_huidong_journal_add_comment,
  // 我的客户
  crm_customer_index,
  crm_customer_call,
  custom_huidong_customer_index,
  crm_customer_add,
  custom_huidong_customer_add,
  crm_customer_task_update,
  custome_huidong_task_update,
  crm_customer_detail,
  custom_huidong_customer_detail,
  crm_customer_follow,
  custom_huidong_customer_follow,
  crm_customer_follow_log,
  crm_customer_audition_schedule,
  crm_customer_audition_arrange,
  crm_customer_audition_cancel,
  custom_huidong_customer_follow_log,
  crm_customer_update,
  crm_customer_transfer,
  custom_huidong_customer_transfer,
  // 我的线索
  crm_leads_index,
  crm_leads_call,
  custom_huidong_leads_index,
  crm_leads_detail,
  custom_huidong_leads_detail,
  crm_leads_transform,
  custom_huidong_leads_transform,
  crm_leads_transfer,
  custom_huidong_leads_transfer,
  crm_leads_follow,
  custom_huidong_leads_follow,
  crm_leads_follow_log,
  custom_huidong_leads_follow_log,
  crm_leads_task_update,
  crm_filter_stduio,
  crm_filter_course,
  crm_filter_channel,
  // 员工列表
  crm_filter_employee,
  crm_filter_get_cascader_employee,
  // 业绩统计
  crm_commision,
  custom_huidong_commision,
  // 数据报表
  analysis_campus_mkt,
  analysis_campus_course,
  analysis_campus_contract,
  analysis_campus_crm,
  analysis_campus_ranking,
  analysis_campus_teacher,
  // 会话事件
  book_events_index,
  // 企业话术
  discourse_index,
  discourse_leads,
  discourse_customer,
  discourse_refresh,
  discourse_click,
  // 聊天记录
  qywx_msg_detail,
  // 客户画像
  qywx_tag_index,
  qywx_external_group_profile,
  qywx_external_message,
  qywx_external_add_message,
  qywx_external_task_update,
  qywx_external_follow,
  qywx_external_follow_log,
  qywx_external_group_members,
  qywx_external_group_related_class,
  classes_schedule,
  qywx_external_group_can_related_class,
  qywx_external_contact_index,
  qywx_external_contact_invited,
  qywx_external_contact_bind,
  qywx_external_contact_recognize,
  qywx_external_contact_course,
  qywx_external_contact_profile,
  qywx_external_contact_contact_way,
  qywx_external_contact_contact_base64,

  namecard_index,
  // 活动二维码
  events_qrcode,
  // 日活跃
  daily_active,
  // 机构注册
  apply_index,
  apply_terms,
  // 公告详情
  oa_announce_detail,
  oa_announce_seen,
  // 审批
  oa_examine,
  oa_examine_detail,
  oa_examine_category,
  oa_examine_add,
  oa_examine_update,
  oa_examine_check,
  oa_examine_check_record,
  oa_examine_step,
  oa_examine_revoke

}
