// 数据校验函数

/**
 * 验证 - 是否未定义
 * @param {*} value
 */
export function isDef(value) {
  return value !== undefined && value !== null
}

/**
* 验证 - 是否是对象
* @param {*} x
*/
export function isObj(x) {
  const type = typeof x
  return x !== null && (type === 'object' || type === 'function')
}

/**
* 验证 - 是否是字符串
* @param {string} str
*/
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
* 验证 - 是否是数字
* @param {Number} value
*/
export function isNumber(value) {
  return /^\d+$/.test(value)
}

/**
* 验证 - 是否是数组
* @param {Array} arg
*/
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
* 验证 - 是否为空数据
* @param {*} obj
*/
export function isEmpty(obj) {
  if (obj == null) {
    return true
  }
  if (isArray(obj)) {
    return obj.length === 0
  }
  if (isString(obj)) {
    return !`${obj}`.trim().length
  }
  if (JSON.stringify(obj) === '{}') {
    return true
  }
  return false
}

// 业务
/**
 * 验证 - 是否是手机号
 * @param {String} val
 */
export function isPhone(value) {
  const reg = /^1[0-9]{10}$/
  return reg.test(value)
}
/**
* 验证 - 是否是邮箱
* @param {String} val
*/
export function isEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}
