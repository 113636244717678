// import qs from 'qs'
import router from './router/index'
import store from '@/store/index'
import wechatAuth from '@/plugins/wechatAuth'
import api from '@/api/index'
import util from '@/common/util'
import storage from 'good-storage'
import QueryString from 'qs'


// 设置APPID
wechatAuth.setAppId(process.env.VUE_APP_WECHAT_APPID)
const whiteList = ['/404','/oauth','/expire','/wechat/events/qrcode-ticket','/ruiyi2021','/events/detail','/events/qrcode-ticket','/cai','/recruit','/recruitDetail','/apply','/maintain','/ps/deliver','/ps/deliver/cai','/ps/deliver/order','/ps/receive'] // 白名单
router.beforeEach(async(to, from, next) => {

   let appid = ''
   if(to.query.appid){
    if(typeof to.query.appid =='string'){
      localStorage.setItem('appid', to.query.appid)
      appid = to.query.appid;
    }
    if(typeof to.query.appid =='object'){
      localStorage.setItem('appid', to.query.appid[0])
      appid = to.query.appid[0];
    }
   }
   if(to.query.app){
    if(typeof to.query.app =='string'){
      localStorage.setItem('appid', to.query.app)
      appid = to.query.app;
    }
    if(typeof to.query.app =='object'){
      localStorage.setItem('appid', to.query.app[0])
      appid = to.query.app[0];
    }
   }

  if(to.query.__token){
    let name = `${appid}-Access-Token`
    let token = to.query.__token
    await store.dispatch('user/setTokenAlone',{token,name})
    await store.dispatch('user/setLoginStatus', 2)
  }

  if(to.query.corpid){
    localStorage.setItem('corpid', to.query.corpid)
  }

  if(to.query.appid){
    if(!to.query.app){
      let name = `${appid}-Access-Token`
      let token = storage.get(name, '')
      if(token){
        await store.dispatch('user/setTokenAlone',{token,name})
        await store.dispatch('user/setLoginStatus', 2)
      }
    }else{
      let name = `${appid}-Access-Token`
      let token = storage.get(name, '')
      if(token){
        await store.dispatch('user/setTokenAlone',{token,name})
        await store.dispatch('user/setLoginStatus', 2)
      }
    }
  }

  // 活动二维码
  if(to.query.out_trade_no){
    await store.dispatch('user/setLoginStatus', 2)
  }
  // add route title
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }

  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) {
    // 白名单中有需要token
    if(localStorage.getItem('is_whiteList')){
      localStorage.removeItem('is_whiteList')
    }else{
      // let all_query = util.GetRequest(window.location.href)
      // let is_appid = localStorage.getItem('appid')
      // all_query.appid = is_appid
      // console.log(all_query);
      // return next({path:to.path,query:all_query})
      if(!to.query.appid){
        let is_appid = localStorage.getItem('appid')
        let obj = {
          ...to.query,
          appid:is_appid
        }
        router.push({path:to.path,query:obj})
        return
      }else{
        return next()
      }
      
    }
  }

  let wxStr = window.navigator.userAgent
  
  if(wxStr.indexOf("wxwork") == -1){
    let openid_name = `${localStorage.getItem('appid')}-openid`
    if(!localStorage.getItem(openid_name)){
      if(localStorage.getItem('first-openid')){
        localStorage.removeItem('first-openid')
      }else{
        await store.dispatch('user/setLoginStatus', 0)
      }
    }
  }

  // 登录状态
  const { loginStatus } = store.getters
  console.log('登录状态', loginStatus)
  switch (Number(loginStatus)) {
    case 0:
      try {
        let res
        let appid = localStorage.getItem('appid') || to.query.appid
        let corpid = to.query.corpid
        await store.dispatch('user/setLoginStatus', 1)
        localStorage.setItem('first-openid',1)
        // 判断是企业微信还是微信
        if(wxStr.indexOf("wxwork") != -1){  
          let strArr = to.fullPath.split('?')
          if(appid !='mMKcL4' && 0){
            let urlStr = `https://h5.wedots.cn/h5/qywx/auth/redi?path=${to.path}&app=${appid}&guest_allow=${to.query.guest_allow}${strArr[1]?`&${strArr[1]}`:''}`
            localStorage.removeItem(`${appid}-Access-Token`)
            window.location.replace(urlStr)
          }else{
            let urlStr = `https://h5.wedots.cn/h5/qywx/auth/redirect?path=${to.path}&app=${appid}&guest_allow=${to.query.guest_allow}${strArr[1]?`&${strArr[1]}`:''}`
            localStorage.removeItem(`${appid}-Access-Token`)
            window.location.replace(urlStr)
          }
          
        }else{
          if(appid=='RGid7' || appid=='c8bEt1' || to.query.jump=='1' || true){
            await store.dispatch('user/setLoginStatus', 0)
            localStorage.removeItem(`${appid}-Access-Token`)
            if(!to.query.path){
              router.replace({ path: '/oauth?'+QueryString.stringify(to.query)+'&path='+to.path+'&app='+appid});
            }else{
              router.replace({ path: '/oauth?'+QueryString.stringify(to.query)});
            }
            return true;
          }else{
              let strArr = to.fullPath.split('?')
              let urlStr = `https://h5.wedots.cn/h5/auth/redirect?path=${to.path}&app=${appid}&guest_allow=${to.query.guest_allow}${strArr[1]?`&${strArr[1]}`:''}`
              localStorage.removeItem(`${appid}-Access-Token`)
              window.location.replace(urlStr)
            }
        }
      } catch (err) {
        console.error(err)
      }
      break
    case 1:
      try {
        console.log('我进来了')
        const code = util.getUrlParam(window.location.href, 'code')
        const corpid = util.getUrlParam(window.location.href, 'corpid')
        const appid = util.getUrlParam(window.location.href, 'app')
        const redirectPath = util.getUrlParam(window.location.href, 'path')
        const guest_allow = util.getUrlParam(window.location.href, 'guest_allow')
        let all_query = util.GetRequest(window.location.href)
        console.log(window.location.href)
        console.log(all_query)
        console.warn('code==', code)
        console.warn('corpid==', corpid)
        console.warn('appid==', appid)
        console.warn('path==', redirectPath)
        console.warn('guest_allow==', guest_allow)
        if(appid){
          localStorage.setItem('appid', appid)
        }
        // 通过code换取token
        let authRes = ''
        let wxStr = window.navigator.userAgent
        // 判断是企业微信还是微信
        if(wxStr.indexOf("wxwork") != -1) {
          authRes = await store.dispatch('user/loginQYWechatAuth',{code,corpid})
        }else{
          authRes = await store.dispatch('user/loginWechatAuth', code)
        }
        let is_appid = localStorage.getItem('appid')
        await store.dispatch('user/setLoginStatus', 2)
        delete all_query.app
        delete all_query.code
        delete all_query.path
        delete all_query.state
        delete all_query.guest_allow
        all_query.appid = is_appid
        if (authRes.data && authRes.data.cellphone) {
          next({ path: redirectPath,query:all_query})
        } else {
          const openid = authRes.data.openid
          all_query.openid = openid
          if(guest_allow == 1){
            next({ path: redirectPath , query:all_query})
          }else{
            all_query.redirect = redirectPath
            next({ path: '/bind-phone', query: all_query})
          }
        }
      } catch (err) {
        await store.dispatch('user/setLoginStatus', 0)
        next('/404')
      }
      break
    case 2:
      let is_appid = localStorage.getItem('appid')
      let obj = {
        ...to.query,
        appid:is_appid
      }
      if(!to.query.appid){
        router.push({path:to.path,query:obj})
        return
      }else if(obj.__token){
        delete obj.__token
        router.push({path:to.path,query:obj})
        return
      }else{
        next()
      }
      break
    default:
      break
  }
})