import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/pages/home/home'),
    meta: {
      title: ''
    }
  },
  {
    name: 'tax',
    path: '/ps/tax',
    component: () => import('@/pages/ps/tax/cai/index'),
    meta: {
      title: '21-22税务指标'
    }
  },
  // {
  //   name: 'deliver',
  //   path: '/ps/deliver',
  //   component: () => import('@/pages/ps/deliver/index'),
  //   meta: {
  //     title: '配送中心'
  //   }
  // },
  // {
  //   name: 'deliverCai',
  //   path: '/ps/deliver/cai',
  //   component: () => import('@/pages/ps/cai/index'),
  //   meta: {
  //     title: '2899弄菜划算配送单'
  //   }
  // },
  // {
  //   name: 'deliverOrder',
  //   path: '/ps/deliver/order',
  //   component: () => import('@/pages/ps/order/index'),
  //   meta: {
  //     title: '历史订单'
  //   }
  // },
  // {
  //   name: 'receive',
  //   path: '/ps/receive',
  //   component: () => import('@/pages/ps/receive/index'),
  //   meta: {
  //     title: '配送发起'
  //   }
  // },
  {
    name: 'user',
    path: '/user',
    component: () => import('@/pages/user/user'),
    meta: {
      title: '学员中心'
    }
  },
  {
    name: 'addUser',
    path: '/user/add',
    component: () => import('@/pages/user/addUser'),
    meta: {
      title: '添加学员'
    }
  },
  {
    name: 'employee',
    path: '/employee',
    component: () => import('@/pages/user/teacher'),
    meta: {
      title: '机构中心'
    }
  },
  {
    name: 'profiles',
    path: '/profile',
    component: () => import('@/pages/user/teacher'),
    meta: {
      title: '机构中心'
    }
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('@/pages/cart/cart'),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'timetable',
    path: '/schedule',
    component: () => import('@/pages/timetable/timetable'),
    meta: {
      title: '学员课表'
    }
  },
  // {
  //   name: 'cai',
  //   path: '/cai',
  //   component: () => import('@/pages/cai/mall'),
  //   meta: {
  //     title: '2899弄菜划算'
  //   }
  // },
  {
    name: 'mall',
    path: '/mall',
    component: () => import('@/pages/mall/mall'),
    meta: {
      title: '商城'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/login'),
    meta: {
      title: '登录/注册'
    }
  },
  {
    name: 'bindPhone',
    path: '/bind-phone',
    component: () => import('@/pages/bind-phone/bind-phone'),
    meta: {
      title: '学员绑定',
      keepAlive: false
    }
  },
  {
    name: 'oauth',
    path: '/oauth',
    component: () => import('@/pages/oauth/index'),
    meta: {
      title: '',
      keepAlive: false
    }
  },
  {
    name: 'studioDetail',
    path: '/studio-detail',
    component: () => import('@/pages/studio/detail'),
    meta: {
      title: '校区详情',
      keepAlive: false
    }
  },
  {
    name: 'teacherDetail',
    path: '/teacher/detail',
    component: () => import('@/pages/teacher/detail'),
    meta: {
      title: '教师详情'
    }
  },
  {
    name: 'teacher',
    path: '/teacher',
    component: () => import('@/pages/teacher/list'),
    meta: {
      title: '教师介绍'
    }
  },
  {
    name: 'classCategory',
    path: '/class-category',
    component: () => import('@/pages/class/category'),
    meta: {
      title: '课程类目'
    }
  },
  {
    name: 'classList',
    path: '/class-list',
    component: () => import('@/pages/class/list'),
    meta: {
      title: '课程信息'
    }
  },
  {
    name: 'classDetail',
    path: '/class-detail',
    component: () => import('@/pages/class/detail'),
    meta: {
      title: '课程详情'
    }
  },
  {
    name: 'orderMembercard',
    path: '/order-membercard',
    component: () => import('@/pages/order/membercard'),
    meta: {
      title: '课程购买'
    }
  },
  {
    name: 'protocolStudent',
    path: '/protocol-student',
    component: () => import('@/pages/protocol/student'),
    meta: {
      title: '学员协议'
    }
  },
  {
    name: 'weistoreproduct',
    path: '/weistore-product',
    component: () => import('@/pages/weistore/product'),
    meta: {
      title: '商品详情'
    }
  },
  {
    name: 'weistoreComment',
    path: '/weistore/comment',
    component: () => import('@/pages/weistore/comment'),
    meta: {
      title: '产品评价'
    }
  },
  {
    name: 'audition',
    path: '/audition',
    component: () => import('@/pages/audition/index'),
    meta: {
      title: '试听登记'
    }
  },
  {
    name: 'goods-reputation',
    path: '/goods-reputation',
    component: () => import('@/pages/goods-reputation/goods-reputation'),
    meta: {
      title: '全部评价'
    }
  },
  {
    name: 'order-submit',
    path: '/order-submit',
    component: () => import('@/pages/order-submit/order-submit'),
    meta: {
      title: '提交订单'
    }
  },
  {
    name: 'order-result',
    path: '/order-result',
    component: () => import('@/pages/order-result/order-result'),
    meta: {
      title: '支付结果页'
    }
  },
  {
    name: 'usableCoupon',
    path: '/usable-coupon',
    component: () => import('@/pages/coupon/usable-coupon'),
    meta: {
      title: '可用优惠券'
    }
  },
  {
    name: 'order-list',
    path: '/order-list',
    component: () => import('@/pages/order-list/order-list'),
    meta: {
      title: '订单列表'
    }
  },
  {
    name: 'order-detail',
    path: '/order-detail',
    component: () => import('@/pages/order-detail/order-detail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    name: 'express',
    path: '/express',
    component: () => import('@/pages/express/express'),
    meta: {
      title: '物流详情'
    }
  },
  {
    name: 'refund',
    path: '/order-refund',
    component: () => import('@/pages/refund/refund'),
    meta: {
      title: '退款'
    }
  },
  {
    name: 'refund-apply',
    path: '/order-refund/apply',
    component: () => import('@/pages/refund/refund-apply'),
    meta: {
      title: '申请售后'
    }
  },
  {
    name: 'notice',
    path: '/notice',
    component: () => import('@/pages/notice/index'),
    meta: {
      title: '消息列表'
    }
  },
  {
    name: 'noticeDetail',
    path: '/notice/detail',
    component: () => import('@/pages/notice/detail'),
    meta: {
      title: '消息详情'
    }
  },
  {
    name: 'address-list',
    path: '/address-list',
    component: () => import('@/pages/address-list/address-list'),
    meta: {
      title: '管理收货地址'
    }
  },
  {
    name: 'address-edit',
    path: '/address-edit',
    component: () => import('@/pages/address-edit/address-edit'),
    meta: {
      title: '管理收货地址'
    }
  },
  {
    name: 'coupon',
    path: '/coupon',
    component: () => import('@/pages/coupon/index'),
    meta: {
      title: '我的优惠券'
    }
  },
  {
    name: 'profile',
    path: '/user/profile',
    component: () => import('@/pages/profile/profile'),
    meta: {
      title: '我的资料'
    }
  },
  {
    name: 'userNamecard',
    path: '/user/namecard',
    component: () => import('@/pages/student/namecard'),
    meta: {
      title: '我的学员码'
    }
  },
  // 头像下面那栏
  {
    name: 'studentContract',
    path: '/student/contract/index',
    component: () => import('@/pages/student/contract'),
    meta: {
      title: '报读课程'
    }
  },
  {
    path: '/student/coupon/index',
    redirect: '/coupon'
  },
  {
    name: 'studentCredits',
    path: '/student/credits/index',
    component: () => import('@/pages/student/credits'),
    meta: {
      title: '积分记录'
    }
  },
  {
    name: 'studentBooking',
    path: '/student/booking/index',
    component: () => import('@/pages/student/booking'),
    meta: {
      title: '上课记录'
    }
  },
  {
    name: 'teacherBooking',
    path: '/teacher/booking/index',
    component: () => import('@/pages/teacher/booking'),
    meta: {
      title: '上课记录'
    }
  },
  // 学员服务
  {
    name: 'studentSchedule',
    path: '/student/schedule/index',
    component: () => import('@/pages/student/schedule'),
    meta: {
      title: '学员课表'
    }
  },
  {
    name: 'studentAskleave',
    path: '/student/ask-leave',
    component: () => import('@/pages/student/ask-leave'),
    meta: {
      title: '请假申请'
    }
  },
  {
    name: 'studentScheduleDetail',
    path: '/student/schedule/detail',
    component: () => import('@/pages/student/schedule-detail'),
    meta: {
      title: '上课详情'
    }
  },
  {
    name: 'studentScheduleComment',
    path: '/student/schedule/schedule-comment',
    component: () => import('@/pages/student/schedule-comment'),
    meta: {
      title: '课程评论'
    }
  },
  {
    name: 'studentScheduleLive',
    path: '/student/schedule/schedule-live',
    component: () => import('@/pages/student/schedule-live'),
    meta: {
      title: '教室摄像头实况'
    }
  },
  {
    name: 'studentLeave',
    path: '/student/schedule/apply_cancel_class',
    component: () => import('@/pages/student/leave'),
    meta: {
      title: '学员请假'
    }
  },
  {
    name: 'studentMessage',
    path: '/student/message',
    component: () => import('@/pages/student/message'),
    meta: {
      title: '学员档案'
    }
  },
  {
    name: 'studentHomework',
    path: '/student/homework/index',
    component: () => import('@/pages/student/homework'),
    meta: {
      title: '我的作业'
    }
  },
  {
    name: 'studentCommentClass',
    path: '/student/class_comment/index',
    component: () => import('@/pages/student/comment-class'),
    meta: {
      title: '课后反馈'
    }
  },
  {
    name: 'studentCommentTeacher',
    path: '/student/comment_to_teacher/index',
    component: () => import('@/pages/student/comment-teacher'),
    meta: {
      title: '评价老师'
    }
  },
  {
    name: 'studentSubmitComment',
    path: '/student/submit-comment',
    component: () => import('@/pages/student/submit-comment'),
    meta: {
      title: '提交反馈'
    }
  },
  {
    name: 'studentCertificate',
    path: '/student/certificate/index',
    component: () => import('@/pages/student/certificate'),
    meta: {
      title: '学员证书'
    }
  },
  {
    name: 'studentAchievement',
    path: '/student/achievement/index',
    component: () => import('@/pages/student/achievement'),
    meta: {
      title: '学员成就'
    }
  },
  {
    name: 'studentClasses',
    path: '/student/classes/index',
    component: () => import('@/pages/student/classes'),
    meta: {
      title: '我的班级'
    }
  },
  {
    name: 'studentOrderList',
    path: '/student/order-list',
    component: () => import('@/pages/student/order-list'),
    meta: {
      title: '我的订单'
    }
  },
  {
    name: 'premium',
    path: '/premium',
    component: () => import('@/pages/premium/premium'),
    meta: {
      title: '课程购买'
    }
  },
  {
    name: 'premiumDetail',
    path: '/premium/detail',
    component: () => import('@/pages/premium/detail'),
    meta: {
      title: '课程详情'
    }
  },
  // 助学工具
  {
    name: 'content',
    path: '/content/index',
    component: () => import('@/pages/content/index'),
    meta: {
      title: '课程专栏'
    }
  },
  {
    name: 'contentDetail',
    path: '/content/detail',
    component: () => import('@/pages/content/detail'),
    meta: {
      title: '课程专栏详情',
      keepAlive: false
    }
  },
  {
    name: 'contentClassDetail',
    path: '/content/class',
    component: () => import('@/pages/content/class'),
    meta: {
      title: '课程详情'
    }
  },
  {
    name: 'credits',
    path: '/credits/index',
    component: () => import('@/pages/credits/index'),
    meta: {
      title: '积分商城'
    }
  },
  {
    name: 'creditsExchange',
    path: '/credits/exchange',
    component: () => import('@/pages/credits/exchange'),
    meta: {
      title: '积分兑换'
    }
  },
  {
    name: 'events',
    path: '/events/index',
    component: () => import('@/pages/events/index'),
    meta: {
      title: '活动中心'
    }
  },
  {
    name: 'eventsMine',
    path: '/events/mine',
    component: () => import('@/pages/events/mine'),
    meta: {
      title: '我的活动'
    }
  },
  {
    name: 'eventsDetail',
    path: '/events/detail',
    component: () => import('@/pages/events/detail'),
    meta: {
      title: '活动详情',
    }
  },
  {
    name: 'eventsSubmit',
    path: '/events/submit',
    component: () => import('@/pages/events/submit'),
    meta: {
      title: '活动报名'
    }
  },
  {
    name: 'ruiyi',
    path: '/ruiyi2021',
    component: () => import('@/pages/events/detailF'),
    meta: {
      title: '活动详情'
    }
  },
  {
    name: 'ruiyiSubmit',
    path: '/ruiyi2021/submit',
    component: () => import('@/pages/events/submitF'),
    meta: {
      title: '活动报名'
    }
  },
  {
    name: 'ruiyiSubmit',
    path: '/ruiyi2021/stepper/submit',
    component: () => import('@/pages/events/stepperSubmitF'),
    meta: {
      title: '活动报名'
    }
  },
  {
    name: 'eventsTicket',
    path: '/events/ticket',
    component: () => import('@/pages/events/ticket'),
    meta: {
      title: '活动票据'
    }
  },
  {
    name: 'suggestion',
    path: '/asuggestion/index',
    component: () => import('@/pages/suggestion/index'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    name: 'contract-pdf',
    path: '/student/contract-pdf',
    component: () => import('@/pages/student/contract-pdf'),
    meta: {
      title: '电子合同'
    }
  },
  // 学生发票申请
  {
    name: 'invoice',
    path: '/invoice/index',
    component: () => import('@/pages/invoice/index'),
    meta: {
      title: '可申请发票列表'
    }
  },
  {
    name: 'invoiceSubmit',
    path: '/invoice/submit',
    component: () => import('@/pages/invoice/submit'),
    meta: {
      title: '申请发票'
    }
  },
  // 教师发票申请
  {
    name: 'teacherInvoice',
    path: '/teacher/invoice/index',
    component: () => import('@/pages/invoice/teacher/index'),
    meta: {
      title: '可申请发票列表'
    }
  },
  {
    name: 'teacherInvoiceSubmit',
    path: '/teacher/invoice/submit',
    component: () => import('@/pages/invoice/teacher/submit'),
    meta: {
      title: '申请发票'
    }
  },

  // 教师服务
  {
    name: 'teacherSchedule',
    path: '/teacher/schedule/index',
    component: () => import('@/pages/teacher/schedule'),
    meta: {
      title: '老师课表',
      keepAlive: false
    }
  },
  {
    name: 'teacherScheduleDetail',
    path: '/teacher/schedule-detail',
    component: () => import('@/pages/teacher/schedule-detail'),
    meta: {
      title: '上课详情'
    }
  },
  {
    name: 'teachercheckin',
    path: '/teacher/checkin',
    component: () => import('@/pages/teacher/checkin'),
    meta: {
      title: '上课签到'
    }
  },
  {
    name: 'teachercheckinAdd',
    path: '/teacher/checkin/add',
    component: () => import('@/pages/teacher/checkin-add'),
    meta: {
      title: '临时插班'
    }
  },
  {
    name: 'teacherAchievement',
    path: '/teacher/achievement',
    component: () => import('@/pages/teacher/achievement'),
    meta: {
      title: '学员成就'
    }
  },
  {
    name: 'teacherAchievementToggle',
    path: '/teacher/achievement-toggle',
    component: () => import('@/pages/teacher/achievement-toggle'),
    meta: {
      title: '学员成就'
    }
  },
  {
    name: 'teacherStudentComment',
    path: '/teacher/comment_to_student/index',
    component: () => import('@/pages/teacher/student-comment'),
    meta: {
      title: '课后反馈'
    }
  },
  {
    name: 'teacherStudentCommentDetail',
    path: '/teacher/comment_to_student/detail',
    component: () => import('@/pages/teacher/student-comment-detail'),
    meta: {
      title: '课后反馈'
    }
  },
  {
    name: 'teacherHomework',
    path: '/teacher/homework/index',
    component: () => import('@/pages/teacher/homework'),
    meta: {
      title: '作业批改'
    }
  },
  {
    name: 'teacherClasses',
    path: '/teacher/classes/index',
    component: () => import('@/pages/teacher/classes'),
    meta: {
      title: '开班记录'
    }
  },
  {
    name: 'teacherClassesDetail',
    path: '/teacher/classes/detail',
    component: () => import('@/pages/teacher/classes-detail'),
    meta: {
      title: '班级详情'
    }
  },
  {
    name: 'teacherAnalysis',
    path: '/teacher/analysis/index',
    component: () => import('@/pages/teacher/analysis'),
    meta: {
      title: '开课统计'
    }
  },
  {
    name: 'teacherSalary',
    path: '/teacher/salary/index',
    component: () => import('@/pages/teacher/salary'),
    meta: {
      title: '工资单'
    }
  },
  {
    name: 'teacherSalaryDetail',
    path: '/teacher/salary/detail',
    component: () => import('@/pages/teacher/salaryDetail'),
    meta: {
      title: '工资单详情'
    }
  },
  // 机构服务
  {
    name: 'campusAnalysis',
    path: '/campus/analysis/index',
    component: () => import('@/pages/campus/analysis'),
    meta: {
      title: '数据报表'
    }
  },
  {
    name: 'campusQrcode',
    path: '/campus/qrcode/scan',
    component: () => import('@/pages/campus/qrcode'),
    meta: {
      title: '扫码签到'
    }
  },

  // {
  //   name: 'favgoods',
  //   path: '/favgoods',
  //   component: () => import('@/pages/user/favgoods'),
  //   meta: {
  //     title: '我的收藏'
  //   }
  // },
  // {
  //   name: 'cashlog',
  //   path: '/cashlog',
  //   component: () => import('@/pages/user/cashlog'),
  //   meta: {
  //     title: '资金明细'
  //   }
  // },
  {
    name: 'huidongPayment',
    path: '/huidongPayment',
    component: () => import('@/pages/payment/huidongPayMent'),
    meta: {
      title: '慧动支付'
    }
  },
  {
    name: 'contractPayment',
    path: '/contractPayment',
    component: () => import('@/pages/payment/contractPayment'),
    meta: {
      title: '入读支付'
    }
  },
  {
    name: 'paymentCenter',
    path: '/paymentCenter',
    component: () => import('@/pages/payment/center'),
    meta: {
      title: '支付中心'
    }
  },
  {
    name: 'orderCenter',
    path: '/orderCenter',
    component: () => import('@/pages/payment/orderCenter'),
    meta: {
      title: '支付中心'
    }
  },
  {
    name: 'payCenter',
    path: '/payCenter',
    component: () => import('@/pages/payment/payCenter'),
    meta: {
      title: '支付中心'
    }
  },
  {
    name: 'paymentAmount',
    path: '/amount',
    component: () => import('@/pages/payment/amount'),
    meta: {
      title: '自定义金额付款'
    }
  },
  {
    name: 'PaymentDirect',
    path: '/direct',
    component: () => import('@/pages/payment/direct'),
    meta: {
      title: '二维码付款'
    }
  },
  {
    name: 'rechargeCenter',
    path: '/recharge/center',
    component: () => import('@/pages/payment/recharge'),
    meta: {
      title: '充值中心'
    }
  },
  {
    name: 'paymentSuccess',
    path: '/paymentSuccess',
    component: () => import('@/pages/payment/paymentSuccess'),
    meta: {
      title: '付款成功'
    },
  },
  {
    name: 'paymentTicket',
    path: '/payment/ticket',
    component: () => import('@/pages/payment/ticket'),
    meta: {
      title: '订单详情'
    },
  },
  {
    name: 'applySuccess',
    path: '/applySuccess',
    component: () => import('@/pages/payment/applySuccess'),
    meta: {
      title: '提交成功'
    },
  },
  {
    name: 'submitSuccess',
    path: '/submitSuccess',
    component: () => import('@/pages/custom/submitSuccess'),
    meta: {
      title: '提交成功'
    },
  },
  {
    name: 'recruit',
    path: '/recruit',
    component: () => import('@/pages/recruit/index'),
    meta: {
      title: '在线报班'
    }
  },
  {
    name: 'reportDetail',
    path: '/recruitDetail',
    component: () => import('@/pages/studio/reportDetail'),
    meta: {
      title: '在线报班',
      keepAlive: false
    }
  },
  {
    name: 'face',
    path: '/face',
    component: () => import('@/pages/face'),
    meta: {
      title: '人脸识别',
      keepAlive: false
    }
  },
  {
    name: 'survey',
    path: '/survey',
    component: () => import('@/pages/custom/survey'),
    meta: {
      title: '信息采集',
      keepAlive: false
    }
  },
  {
    name: 'huidongApply',
    path: '/huidong-apply',
    component: () => import('@/pages/custom/huidong/survey/huidongApply'),
    meta: {
      title: '慧动入读'
    }
  },
  {
    name: 'contractApply',
    path: '/contract-apply',
    component: () => import('@/pages/survey/huidongApply'),
    meta: {
      title: '学员入读'
    }
  },
  {
    name: 'appointment',
    path: '/appointment',
    component: () => import('@/pages/appointment'),
    meta: {
      title: '学员约课'
    }
  },
  {
    name: 'appointmentApply',
    path: '/appointment/apply',
    component: () => import('@/pages/appointment/apply'),
    meta: {
      title: '自主约课'
    }
  },
  {
    name: 'appointment',
    path: '/huidong/appointment',
    component: () => import('@/pages/custom/huidong/appointment'),
    meta: {
      title: '学员约课',
      keepAlive: false
    }
  },
  {
    name: 'appointment',
    path: '/huidong/appointment-apply',
    component: () => import('@/pages/custom/huidong/appointment/apply'),
    meta: {
      title: '确认约课'
    }
  },
  {
    name: 'signature',
    path: '/signature',
    component: () => import('@/pages/custom/huidong/survey/signature'),
    meta: {
      title: '慧动学员入读签名补填'
    }
  },
  {
    name: 'invited',
    path: '/crm/invited/index',
    component: () => import('@/pages/teacher/invited/index'),
    meta: {
      title: '邀约到访'
    }
  },
  {
    name: 'huidong_invited',
    path: '/custom/huidong/invited',
    component: () => import('@/pages/custom/huidong/invited/index'),
    meta: {
      title: '邀约到访'
    }
  },
  {
    name: 'commision',
    path: '/crm/commision/index',
    component: () => import('@/pages/commision/index'),
    meta: {
      title: '业绩统计'
    }
  },
  {
    name: 'custom_commision',
    path: '/custom/huidong/commision',
    component: () => import('@/pages/custom/huidong/commision/index'),
    meta: {
      title: '业绩统计'
    }
  },
  {
    name: 'custom_analysis',
    path: '/custom/huidong/analysis',
    component: () => import('@/pages/custom/huidong/crm/index'),
    meta: {
      title: '招生简报'
    }
  },
  {
    name: 'crm_analysis',
    path: '/crm/analysis/index',
    component: () => import('@/pages/crm/index'),
    meta: {
      title: '招生简报'
    }
  },
  {
    name: 'custom_customer',
    path: '/custom/huidong/customer',
    component: () => import('@/pages/custom/huidong/customer/index'),
    meta: {
      title: '我的客户',
    }
  },
  {
    name: 'custom_customer_detail',
    path: '/custom/huidong/customer/detail',
    component: () => import('@/pages/custom/huidong/customer/customerDetail/index'),
    meta: {
      title: '客户详情'
    }
  },
  {
    name: 'custom_customer_add',
    path: '/custom/huidong/customer/add',
    component: () => import('@/pages/custom/huidong/customer/addCustomer'),
    meta: {
      title: '新增客户'
    }
  },
  {
    name: 'crm_customer',
    path: '/crm/customer/index',
    component: () => import('@/pages/customer/index'),
    meta: {
      title: '我的客户',
    }
  },
  {
    name: 'crm_customer_detail',
    path: '/crm/customer/detail',
    component: () => import('@/pages/customer/customerDetail/index'),
    meta: {
      title: '客户详情'
    }
  },
  {
    name: 'crm_customer_add',
    path: '/crm/customer/add',
    component: () => import('@/pages/customer/addCustomer'),
    meta: {
      title: '新增客户'
    }
  },
  {
    name: 'custom_leads',
    path: '/custom/huidong/leads',
    component: () => import('@/pages/custom/huidong/leads/index'),
    meta: {
      title: '我的线索'
    }
  },
  {
    name: 'custom_leads_detail',
    path: '/custom/huidong/leads/detail',
    component: () => import('@/pages/custom/huidong/leads/detail'),
    meta: {
      title: '线索详情'
    }
  },
  {
    name: 'crm_leads',
    path: '/crm/leads/index',
    component: () => import('@/pages/leads/index'),
    meta: {
      title: '我的线索'
    }
  },
  {
    name: 'crm_leads_detail',
    path: '/crm/leads/detail',
    component: () => import('@/pages/leads/detail'),
    meta: {
      title: '线索详情'
    }
  },
  {
    name: 'huidongTeacherApply',
    path: '/huidong/invited/teacherApply',
    component: () => import('@/pages/custom/huidong/survey/huidongTeacherApply'),
    meta: {
      title: '慧动入读'
    }
  },
  {
    name: 'teacherApply',
    path: '/teacher/invited/teacherApply',
    component: () => import('@/pages/survey/huidongTeacherApply'),
    meta: {
      title: '学员入读'
    }
  },
  {
    name: 'invitedDetail',
    path: '/teacher/invited/detail',
    component: () => import('@/pages/teacher/invited/invitedDetail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    name: 'huidongInvitedDetail',
    path: '/huidong/invited/detail',
    component: () => import('@/pages/custom/huidong/invited/invitedDetail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    name: 'zhuqiao',
    path: '/custom_zhuqiao',
    component: () => import('@/pages/custom/zhuqiao'),
    meta: {
      title: 'zhuqiao'
    }
  },
  {
    name: 'zhuqiao_refund',
    path: '/custom/zhuqiao/refund',
    component: () => import('@/pages/custom/zhuqiao/refund'),
    meta: {
      title: '筑桥退费申请'
    }
  },
  {
    name: 'zhuqiao_refund_success',
    path: '/custom/zhuqiao/refund/success',
    component: () => import('@/pages/custom/zhuqiao/refund/submitSuccess'),
    meta: {
      title: '申请成功'
    }
  },
  {
    name: 'campus_schedule',
    path: '/campus/schedule',
    component: () => import('@/pages/campus/schedule/index'),
    meta: {
      title: '校区课表'
    }
  },
  {
    name: 'campus_monitor',
    path: '/campus/monitor',
    component: () => import('@/pages/campus/monitor/index'),
    meta: {
      title: '校区实况'
    }
  },
  {
    name: 'campus_schedule_detail',
    path: '/campus/schedule/summary',
    component: () => import('@/pages/campus/schedule/detail'),
    meta: {
      title: '备课详情'
    }
  },
  {
    name: 'journal',
    path: '/campus/journal/index',
    component: () => import('@/pages/journal/index'),
    meta: {
      title: '日报'
    }
  },
  {
    name: 'journalDetail',
    path: '/campus/journal/detail',
    component: () => import('@/pages/journal/journalDetail'),
    meta: {
      title: '日报详情'
    }
  },
  {
    name: 'bookEvents',
    path: '/qywx/crm/bookEvents',
    component: () => import('@/pages/book-events/index'),
    meta: {
      title: '会话事件'
    }
  },
  {
    name: 'discourse',
    path: '/qywx/crm/discourse',
    component: () => import('@/pages/discourse/index'),
    meta: {
      title: '企业话术'
    }
  },
  {
    name: 'chatRecord',
    path: '/qywx/crm/chatRecord',
    component: () => import('@/pages/chatRecord/index'),
    meta: {
      title: '聊天记录',
      keepAlive: false
    }
  },
  {
    name: 'groupDetail',
    path: '/qywx/crm/profile',
    component: () => import('@/pages/qywxProfile/index'),
    meta: {
      title: '客户画像'
    }
  },
  {
    name: 'binging',
    path: '/qywx/crm/binging',
    component: () => import('@/pages/qywxProfile/binging'),
    meta: {
      title: '绑定学员'
    }
  },
  {
    name: 'qrcodeTicket',
    path: '/events/qrcode-ticket',
    component: () => import('@/pages/qrcodeTicket/index'),
    meta: {
      title: '活动信息'
    }
  },
  {
    name: 'namecard',
    path: '/namecard',
    component: () => import('@/pages/namecard/index'),
    meta: {
      title: '企业微信联系名片'
    }
  },
  {
    name: 'personCard',
    path: '/qywx/crm/person-card',
    component: () => import('@/pages/personCard/index'),
    meta: {
      title: '一客一码'
    }
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/pages/contact/index'),
    meta: {
      title: '关联家属'
    }
  },
  {
    name: 'contactEdit',
    path: '/contact/edit',
    component: () => import('@/pages/contact/edit'),
    meta: {
      title: '关联家属'
    }
  },
  {
    name: 'print',
    path: '/print',
    component: () => import('@/pages/custom/print'),
    meta: {
      title: '周相册'
    }
  },
  {
    name: 'apply',
    path: '/apply',
    component: () => import('@/pages/apply/index'),
    meta: {
      title: '申请试用微点云助教'
    }
  },
  {
    name: 'applySuccesss',
    path: '/apply/applySuccess',
    component: () => import('@/pages/apply/applySuccess'),
    meta: {
      title: '申请成功'
    }
  },
  {
    name: 'wallet',
    path: '/wallet/index',
    component: () => import('@/pages/wallet/index'),
    meta: {
      title: '我的钱包'
    }
  },
  {
    name: 'maintain',
    path: '/maintain',
    component: () => import('@/pages/maintain/index'),
    meta: {
      title: '系统维护'
    }
  },
  {
    name: 'announce',
    path: '/oa/announce',
    component: () => import('@/pages/oa/announce'),
    meta: {
      title: '公告'
    }
  },
  {
    name: 'approve',
    path: '/oa/approve',
    component: () => import('@/pages/oa/approve'),
    meta: {
      title: '审批'
    }
  },
  {
    name: 'approve',
    path: '/oa/approve/detail',
    component: () => import('@/pages/oa/approve/approveDetail'),
    meta: {
      title: '审批详情'
    }
  },
  {
    name: 'approve',
    path: '/oa/approve/add',
    component: () => import('@/pages/oa/approve/addApprove'),
    meta: {
      title: '新建审批'
    }
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/pages/error-page/404'),
    meta: {
      title: '404'
    }
  },
  {
    name: 'expire',
    path: '/expire',
    component: () => import('@/pages/error-page/expire'),
    meta: {
      title: '系统服务已过期'
    }
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router