/**
 * 工具类
 * @author Joeshu
 */

// localStorage 简易API封装
export const storage = {
  set(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data))
  },
  get(key) {
    return JSON.parse(window.localStorage.getItem(key))
  },
  remove(key) {
    window.localStorage.removeItem(key)
  },
  clearAll() {
    window.localStorage.clear()
  }
}
// sessionStorage 简易API封装
export const sessionStorage = {
  set(key, data) {
    window.sessionStorage.setItem(key, JSON.stringify(data))
  },
  get(key) {
    return JSON.parse(window.sessionStorage.getItem(key))
  },
  remove(key) {
    window.sessionStorage.removeItem(key)
  },
  clearAll() {
    window.sessionStorage.clear()
  }
}
/**
 * 函数防抖
 * @param {callback} fn 事件回调
 * @param {number} delay 每次推迟执行的等待时间
 */
export const debounce = function(fn, delay) {
  let last = 0
  let timer = null
  return function() {
    const context = this
    const args = arguments
    const now = +new Date()

    if (now - last < delay) {
      clearTimeout(timer)
      timer = setTimeout(function() {
        last = now
        fn.apply(context, args)
      }, delay)
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}
/**
 * 函数节流
 * @param {*} fn 事件回调
 * @param {*} interval 时间间隔的阈值
 */
export const throttle = function(fn, interval) {
  let last = 0
  return function() {
    const context = this
    const args = arguments
    const now = +new Date()

    if (now - last >= interval) {
      last = now
      fn.apply(context, args)
    }
  }
}

/**
 * 获取指定url参数值
 * @param {string} url 事件回调
 * @param {string} name 时间间隔的阈值
 */
function getUrlParam(url, name) {
  try {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = url.split('?')[1].match(reg)
    if (r != null) return decodeURIComponent(r[2])
    return null
  } catch (e) {
    return null
  }
}

/**
 * 获取所有url参数值
 * @param {string} url 事件回调
 * @param {string} name 时间间隔的阈值
 */
function GetRequest(urlStr) {
  var url = "?" + urlStr.split("?")[1];
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
  }
  return theRequest;
}

/**
 * 将时间段格式化
 */
export const formatTime = function(second){
  second = Math.ceil(second)
  var minute = 0
  var hours = 0
  if(second > 60){
    minute = parseInt(second/60)
    second = parseInt(second%60)
    if(minute > 60){
      hours = parseInt(minute/60)
      minute = parseInt(minute%60)
    }
  }
  var time = ""
  time += parseInt(second) + '秒'
  if(minute != 0){
    time = parseInt(minute) + '分' + time
  }
  if(hours > 0){
    time = parseInt(hours) + '小时' + time
  }
  return time
}

/**
 * 文件大小格式化
 */
export const RenderSize = function (value){
  if(null==value||value==''){
      return "0 Bytes";
  }
  var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
  var index=0;
  var srcsize = parseFloat(value);
  index=Math.floor(Math.log(srcsize)/Math.log(1024));
  var size =srcsize/Math.pow(1024,index);
  size=size.toFixed(2);//保留的小数位数
  return size+unitArr[index];
}

/**
 * 数字格式化
 */
export const toThousands = function (num) {
    var num = (num || 0).toString(), result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
    if (num) { result = num + result; }
    return result;
}

/**
 * 通过身份证获取出生日期
 */
export const getBirthday = function (idCard) {
  // 验证15位和18位身份证
  var reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
  if(reg.test(idCard)){
    var birthday = ""
    if(idCard.length == 15){
      birthday = "19"+idCard.substr(6,6)
    } else if(idCard.length == 18){
      birthday = idCard.substr(6,8)
    }
    return birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-")
  }else{
    return null
  }
}


export default {
  storage, // h后续废除
  sessionStorage, // h后续废除
  debounce,
  throttle,
  GetRequest,
  getUrlParam,
  formatTime,
  RenderSize,
  toThousands,
  getBirthday
}
