<template>
  <div id="app">
    <keep-alive :include="catch_components">
        <router-view v-wechat-title='$route.meta.title'></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { loadToken } from '@/utils/cache'
import { wechatConfig, weWorkchatConfig } from '@/common/wx'
export default {
  data() {
    return {
      info:''
    }
  },
  computed: {
    catch_components() {
      return this.$store.state.app.catch_components
    },
  },
  created() {
    this.$api.brand_index().then(res => {
      this.$store.commit('app/updateBrandInfo', res.data)
    })
    // 判断是企业微信还是微信
    // console.log(`App created`)
    let appid = localStorage.getItem('appid')
    let name = `${appid}-Access-Token`
    let token = localStorage.getItem(name)
    let str = window.navigator.userAgent
    if(str.indexOf("wxwork") != -1){
      weWorkchatConfig()
      if(token){
        this.$api.daily_active({ access_type : 'h5-qywx-employee' })
      }
    }else{
      wechatConfig()
      console.log(token)
      if(token){
        this.$api.daily_active({ access_type : 'h5' })
      }
    }
    this.$api.campus_tabbar()
    .then(res=>{
      this.$store.dispatch('app/set_tabbar',res.data)
    })
  },
}
</script>

<style lang="less">
@import "~@styles/index.css";
@import "~@styles/iconfont.css";
@import "~@styles/reset.css";
// @import '~@styles/variables.less';

// 只适用于页面里只含有一种类型的按钮的情况下
// [class^="btn"]:active{
//   opacity: .8;
// }
.btn-area:active,.btn-fav:active,.btn-danger:active,.btn-warning:active,.btn-gray:active{
  opacity: .8;
}
/* */
.fz10 {
  font-size: 10px;
}
.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
.fz16 {
  font-size: 16px;
}
.fz18 {
  font-size: 18px;
}
.fz24 {
  font-size: 24px;
}
.ml5 {
  margin-left: 5px!important;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.pd50 {
  padding-bottom: 50px;
}
.pd100 {
  padding-bottom: 100px;
}
.bgf {
  background: #fff;
}
.container-padding {
  margin: 15px;
  position: relative;
}
// .disabled{
//   pointer-events: none;
// }
/* common */
.copyright{
  color:#999;
  font-size: 10px;
  line-height:2;
  text-align:center;
}
.common-h2 {
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  &-title {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
  }
  &-title::before {
    content: "";
    background: url(./assets/line_askew.png);
    background-size: cover;
    width: 20px;
    height: 6px;
    position: absolute;
    left: -30px;
    top: 25px;
    font-size: 12px;
    color: #666;
  }
  &-title::after {
    content: "";
    background: url(./assets/line_askew.png);
    background-size: cover;
    width: 20px;
    height: 6px;
    position: absolute;
    right: -30px;
    top: 25px;
    font-size: 12px;
    color: #666;
  }
}
// 富文本标签CLASS
.article-content img,.content img {
  max-width: 100% !important;
  height: auto !important;
}

.affix-bar{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // padding: 10px 0;
  background: #f8f8f8;
}
</style>
